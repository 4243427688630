import { Tag, Variant } from '../types';

const Config: Record<Variant, Tag> = {
  text: 'p',
  textSmall: 'p',
  promoHeader: 'h1',
  extraPromoHeader: 'h1',
  mainHeader: 'h2',
  sectionHeader: 'h3',
  caption: 'p',
  captionSmall: 'p',
  display: 'p',
  displayM: 'p',
  displayX: 'p',
};

const useTagName = (variant: Variant, tag?: Tag): Tag => {
  if (tag) return tag;

  return Config[variant];
};

export default useTagName;
