export const SHOW_MODAL = {
  request: 'SHOW_MODAL_REQUEST',
  success: 'SHOW_MODAL_SUCCESS',
  fail: 'SHOW_MODAL_FAIL',
};

export const HIDE_MODAL = {
  request: 'HIDE_MODAL_REQUEST',
  success: 'HIDE_MODAL_SUCCESS',
  fail: 'HIDE_MODAL_FAIL',
};

export const showModal = (modal = 'MODAL_SIGNUP', modalData = { title: '', description: '' }) => ({
  type: SHOW_MODAL.success,
  payload: {
    modal,
    modalData,
  },
});

export const hideModal = () => ({
  type: HIDE_MODAL.success,
});
