import { createSelector } from 'reselect';

import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import intersectionBy from 'lodash/intersectionBy';

import {
  FETCH_QUESTIONNAIRE_ANSWERS,
  FETCH_QUESTIONNAIRE,
  SELECT_QUESTIONNAIRE_ANSWER,
  SELECT_QUESTIONNAIRE_SUBANSWER,
  SELECT_QUESTIONNAIRE_NEXT_QUESTION,
  SELECT_QUESTIONNAIRE_PREV_QUESTION,
  SEND_QUESTIONNAIRE_ANSWERS,
  CHANGE_OTHER_VALUE,
  CHANGE_OTHER_SUBVALUE,
  CHANGE_RUNNING_QUESTIONNAIRE_STATE,
} from '../../actions/questionnaire';

const initialState = {
  questions: null,
  userAnswersIds: null,
  activeQuestionId: null,
  activeQuestionAnswersId: null,
  questionnaireRunning: false,
  isQuestionnaireAnswered: false,
  isQuestionnaireSending: false,
  answers: null,
};

// get filtered question's answers by id (:not a selector)
export const getSortedQuestions = questions => {
  if (!questions) return null;

  const filteredQuestions = questions
    .filter(item => !!item.answers)
    .map(question => {
      const filteredAnswers = question.answers.sort((a, b) => a.order - b.order);
      const filteredAnswersWithSubAnswers = filteredAnswers.map(answer => {
        if (answer.items) {
          const filteredSubAnswers = answer.items.sort((a, b) => a.order - b.order);

          return { ...answer, items: filteredSubAnswers };
        }
        return answer;
      });
      return { ...question, answers: filteredAnswersWithSubAnswers };
    });

  return filteredQuestions;
};

// get questionnaire running state
export const getQuestionnaireRunningState = state => {
  const { questionnaireRunning } = state.questionnaire;

  return questionnaireRunning || null;
};

// get user selected sub answers
export const getUserSelectedSubAnswers = createSelector(
  state => state.questionnaire,
  ({ activeQuestionId, userAnswersIds }) => {
    const currentAnswers = userAnswersIds ? userAnswersIds[activeQuestionId] : null;

    if (currentAnswers) {
      const currentSubAnswers = currentAnswers.filter(
        answer => answer.subAnswers && answer.subAnswers.length,
      );

      return currentSubAnswers && currentSubAnswers.length ? currentSubAnswers[0].subAnswers : null;
    }
    return null;
  },
);

// get subanswers by active question
export const getActiveSubAnswers = createSelector(
  state => state.questionnaire,
  ({ activeQuestionId, userAnswersIds, questions }) => {
    const currentQuestion =
      questions && activeQuestionId
        ? questions.filter(question => {
            return question.id === activeQuestionId;
          })[0]
        : {};

    const currentQuestionAnswers = currentQuestion.answers || null;
    const currentQuestionAnswerId =
      userAnswersIds && userAnswersIds[activeQuestionId] && userAnswersIds[activeQuestionId].length
        ? userAnswersIds[activeQuestionId][0].id
        : null;

    const currentQuestionSubAnswers = currentQuestionAnswers
      ? currentQuestionAnswers.filter(answer => {
          return answer.id === currentQuestionAnswerId;
        })[0]
      : null;

    return currentQuestionSubAnswers && currentQuestionSubAnswers.items
      ? {
          answerId: currentQuestionAnswerId,
          items: currentQuestionSubAnswers.items,
        }
      : null;
  },
);

// get answers by active question
export const getActiveAnswers = createSelector(
  state => state.activeQuestionId,
  state => state.questions,
  (activeQuestionId, questions) => {
    const answers = questions
      ? questions.reduce((acc, question) => {
          if (question.id === activeQuestionId) {
            return [...question.answers];
          }
          return acc;
        }, [])
      : null;

    // find answers by active question id
    return answers;
  },
);

// get user answers question
export const getUserQuestionAnswers = state => {
  return state.userAnswersIds && state.userAnswersIds[state.activeQuestionId]
    ? state.userAnswersIds[state.activeQuestionId]
    : [];
};

// get current question index
export const getCurrentQuestionIndex = state => {
  const questions = state.questions ? state.questions.map(question => question.id) : [];

  return questions.indexOf(state.activeQuestionId);
};

// get questionnaire questions
export const getQuestionnaireItems = state => {
  return state.questions || [];
};

// get active question by id
export const getActiveQuestion = state => {
  const { questions, activeQuestionId } = state;

  return questions
    ? questions.filter(question => {
        return question.id === activeQuestionId;
      })[0]
    : null;
};

// get user answers
export const getUsersAnswers = state => state.questionnaire.userAnswersIds || {};

// get questions for saga
export const getQuestions = state => {
  return cloneDeep(state.questionnaire.questions) || [];
};

// get validity of Other
export const getOtherValidity = createSelector(
  state => getUserQuestionAnswers(state),
  state => getActiveAnswers(state),
  (userAnswers, answers) => {
    // find intersections between user and questionnaire answers
    const intersectionedAnswers = answers ? intersectionBy(answers, userAnswers, 'id') : null;
    // find other answer if exist
    const otherAnswer = intersectionedAnswers
      ? intersectionedAnswers.reduce((acc, answer) => {
          return answer.textField ? { ...answer } : acc;
        }, {})
      : null;

    if (!otherAnswer || !otherAnswer.id) {
      return true;
    }

    const isOtherValid =
      otherAnswer && otherAnswer.validation ? otherAnswer.validation.valid : false;

    return isOtherValid;
  },
);

// get validity of subOther
export const getSubOtherValidity = createSelector(
  state => getUserSelectedSubAnswers(state),
  state => getActiveSubAnswers(state),
  (userSubAnswers, subAnswers) => {
    // get other of sub-answer questions
    const otherSubAnswer = subAnswers
      ? subAnswers.items.reduce((acc, subAnswer) => {
          if (userSubAnswers && subAnswer.textField) {
            // if user answer persists in sub answers
            return userSubAnswers.indexOf(subAnswer.id) > -1 ? { ...subAnswer } : acc;
          }
          return acc;
        }, {})
      : null;

    // if sub-other doesn't exist then return true
    if (!otherSubAnswer || !otherSubAnswer.id) {
      return true;
    }

    // check validity state for sub-other
    const isSubOtherValid =
      otherSubAnswer && otherSubAnswer.validation ? otherSubAnswer.validation.valid : false;

    return isSubOtherValid;
  },
);

// get user questionnaire answered
export const getIsQuestionnaireAnswered = state => state.isQuestionnaireAnswered;

// get just answers lists
export const getAnswersList = state => state.questionnaire.answers;

// merging answers ids list with questions
const mergedAnswersList = (questionnaireItems, userAnswersIds) => {
  if (!questionnaireItems || !questionnaireItems.length || !userAnswersIds) return null;

  const formedCurrentQuestionAnswers = Object.keys(userAnswersIds).map(userAnswerId => {
    const question = find(questionnaireItems, ['id', parseInt(userAnswerId, 10)]);
    const currentAnswers = userAnswersIds[userAnswerId];

    if (!question) {
      return {};
    }

    if (!currentAnswers && question) {
      const questionData = { ...question };
      delete questionData.answers;

      return questionData;
    }

    question.answers = intersectionBy(question.answers, currentAnswers, 'id');
    question.answers = question.answers.map(answer => {
      const answerData = { ...answer };
      delete answerData.recommendationTag;
      delete answerData.validation;

      return answerData;
    });

    question.answers = question.answers.map(answer => {
      const currentAnswer = find(currentAnswers, { id: answer.id });

      if (!currentAnswer.subAnswers) return answer;

      // eslint-disable-next-line no-param-reassign
      answer.items = answer.items.filter(item => {
        return currentAnswer.subAnswers.indexOf(item.id) !== -1;
      });

      // eslint-disable-next-line no-param-reassign
      answer.items = answer.items.map(innerAnswer => {
        const answerData = { ...innerAnswer };
        delete answerData.recommendationTag;
        delete answerData.validation;

        return answerData;
      });

      return answer;
    });

    return question;
  });

  return formedCurrentQuestionAnswers || null;
};

export const getCurrentAnswersList = createSelector(
  state => cloneDeep(getQuestionnaireItems(state.questionnaire)),
  state => cloneDeep(getUsersAnswers(state)),
  (questionnaireItems, userAnswersIds) => {
    return mergedAnswersList(questionnaireItems, userAnswersIds);
  },
);

// don't code questionnairies like this, it is terrible selector :)
export const getIsQuestionnaireUpdated = createSelector(
  state => cloneDeep(getQuestionnaireItems(state.questionnaire)),
  state => cloneDeep(getUsersAnswers(state)),
  state => cloneDeep(getAnswersList(state)),
  (questionnaireItems, userAnswersIds, answersList) => {
    const formedCurrentQuestionAnswers = mergedAnswersList(questionnaireItems, userAnswersIds);

    if (
      !formedCurrentQuestionAnswers ||
      !formedCurrentQuestionAnswers.length ||
      !answersList ||
      !answersList.length
    ) {
      return false;
    }

    return !isEqual(formedCurrentQuestionAnswers, answersList);
  },
);

// get is questionnaire sending to the server
export const getIsQuestionnaireSending = state => state.questionnaire.isQuestionnaireSending;

export default function Index(state = initialState, action) {
  switch (action.type) {
    case FETCH_QUESTIONNAIRE_ANSWERS.success: {
      const answered = action.payload ? !!action.payload.length : false;
      const { questions } = state;

      // format user answers for state structure
      const userAnswersIds = answered
        ? action.payload.reduce((acc, answer) => {
            acc[answer.id] =
              answer.answers && answer.answers.length
                ? answer.answers.map(innerAnswer => ({
                    id: innerAnswer.id,
                    subAnswers: innerAnswer.items ? innerAnswer.items.map(item => item.id) : null,
                  }))
                : null;

            return acc;
          }, {})
        : null;

      const defaultOtherValidation = {
        highlighted: true,
        message: null,
        valid: true,
      };

      // exchange current question change
      const questionsWithOther =
        answered && questions
          ? questions.map(question => {
              const foundQuestion = find(action.payload, ['id', question.id]);

              // eslint-disable-next-line no-param-reassign
              question.answers = question.answers.map(answer => {
                const foundQuestionAnswer = foundQuestion
                  ? find(foundQuestion.answers, ['id', answer.id])
                  : false;

                if (!foundQuestionAnswer) return answer;

                if (answer.textField && !answer.items) {
                  return {
                    ...answer,
                    textValue: foundQuestionAnswer.textValue,
                    validation: defaultOtherValidation,
                  };
                }
                if (answer.items) {
                  return {
                    ...answer,
                    items: answer.items.map(item => {
                      const foundAnswerSubAnswer = find(foundQuestionAnswer.items, ['id', item.id]);

                      if (!foundAnswerSubAnswer) return item;

                      if (item.textField) {
                        return {
                          ...item,
                          textValue: foundAnswerSubAnswer.textValue,
                          validation: defaultOtherValidation,
                        };
                      }
                      return item;
                    }),
                  };
                }
                return answer;
              });

              return question;
            })
          : questions;

      return {
        ...state,
        isQuestionnaireAnswered: answered,
        userAnswersIds,
        questions: questionsWithOther,
        answers: action.payload,
      };
    }
    case CHANGE_RUNNING_QUESTIONNAIRE_STATE: {
      return {
        ...state,
        questionnaireRunning: action.payload,
      };
    }
    case SEND_QUESTIONNAIRE_ANSWERS.request: {
      return {
        ...state,
        isQuestionnaireSending: true,
      };
    }
    case SEND_QUESTIONNAIRE_ANSWERS.failure: {
      return {
        ...state,
        isQuestionnaireSending: false,
      };
    }
    case SEND_QUESTIONNAIRE_ANSWERS.success: {
      return {
        ...state,
        isQuestionnaireSending: false,
      };
    }
    case SELECT_QUESTIONNAIRE_NEXT_QUESTION: {
      const currentIndex = action.payload || action.payload === null ? action.payload : 0;

      if (currentIndex === null) {
        return {
          ...state,
          activeQuestionId: currentIndex,
        };
      }
      if (currentIndex || currentIndex === 0) {
        return {
          ...state,
          activeQuestionId: state.questions[currentIndex].id,
        };
      }
      return {
        ...state,
      };
    }
    case SELECT_QUESTIONNAIRE_PREV_QUESTION: {
      const currentIndex = action?.payload || 0;

      return {
        ...state,
        activeQuestionId: state.questions[currentIndex]?.id,
      };
    }
    case SELECT_QUESTIONNAIRE_SUBANSWER: {
      const { activeQuestion, subAnswer, answerId } = action.payload;

      const userAnswer =
        state.userAnswersIds[activeQuestion.id].map(answer => {
          if (answer.id === answerId) {
            const { subAnswers } = answer;

            if (subAnswers && subAnswers.length) {
              const isExist = subAnswers.filter(ans => ans === subAnswer.id)[0];
              const filteredSubAnswers = subAnswers.filter(ans => ans !== subAnswer.id);

              return isExist
                ? {
                    ...answer,
                    subAnswers: [...filteredSubAnswers],
                  }
                : {
                    ...answer,
                    subAnswers: [...filteredSubAnswers, subAnswer.id],
                  };
            }
            return {
              ...answer,
              subAnswers: [subAnswer.id],
            };
          }
          return answer;
        }) || [];

      return {
        ...state,
        userAnswersIds: {
          ...state.userAnswersIds,
          [activeQuestion.id]: userAnswer,
        },
      };
    }
    case SELECT_QUESTIONNAIRE_ANSWER: {
      // get active question with selector
      const { multiSelection } = getActiveQuestion(state);
      // get user answers for current question
      const answers = getUserQuestionAnswers(state);
      // get filtered user answers if already exists
      const filteredAnswers = answers.filter(answer => answer.id !== action.payload.id);

      const isExist = answers.filter(answer => answer.id === action.payload.id)[0];
      const answer = isExist
        ? null
        : {
            id: action.payload.id,
            subAnswers: null,
          };

      return answer
        ? {
            ...state,
            userAnswersIds: {
              ...state.userAnswersIds,
              [state.activeQuestionId]: [...(multiSelection ? filteredAnswers : []), answer],
            },
          }
        : {
            ...state,
            userAnswersIds: {
              ...state.userAnswersIds,
              [state.activeQuestionId]: [...(multiSelection ? filteredAnswers : [])],
            },
          };
    }
    case CHANGE_OTHER_SUBVALUE: {
      // get decomposed textvalue and answer object
      const { answer, textValue, validation } = action.payload;
      // get active question with selector
      const activeQuestion = getActiveQuestion(state);
      // get all questions
      const questions = getQuestionnaireItems(state);

      return {
        ...state,
        questions: questions.map(item => {
          if (item.id === activeQuestion.id) {
            const editedAnswers = item.answers.map(itemAnswer => {
              if (itemAnswer.items) {
                const editedSubAnswers = itemAnswer.items.map(itemSubAnswer => {
                  if (itemSubAnswer.id === answer.id) {
                    return { ...itemSubAnswer, textValue, validation };
                  }
                  return itemSubAnswer;
                });

                return { ...itemAnswer, items: editedSubAnswers };
              }
              return itemAnswer;
            });

            return { ...item, answers: editedAnswers };
          }
          return item;
        }),
      };
    }
    case CHANGE_OTHER_VALUE: {
      // get decomposed textvalue and answer object
      const { answer, textValue, validation } = action.payload;
      // get active question with selector
      const activeQuestion = getActiveQuestion(state);
      // get all questions
      const questions = getQuestionnaireItems(state);

      return {
        ...state,
        questions: questions.map(item => {
          if (item.id === activeQuestion.id) {
            const editedAnswers = item.answers.map(itemAnswer => {
              if (itemAnswer.id === answer.id) {
                return { ...itemAnswer, textValue, validation };
              }
              return itemAnswer;
            });

            return { ...item, answers: editedAnswers };
          }
          return item;
        }),
      };
    }
    case FETCH_QUESTIONNAIRE.success: {
      // get sorting questions by id
      const sortedQuestions = getSortedQuestions(action.payload.items);

      return {
        ...state,
        questions: sortedQuestions,
        activeQuestionId: sortedQuestions[0] ? sortedQuestions[0].id : null,
      };
    }
    default:
      return state;
  }
}
