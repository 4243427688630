import { Action, StoreType } from 'reducers/types';
import { FETCH_ACTIVITIES } from '../../actions/activities';

import { Activity } from './types';

const initialState = {
  loading: false,
  error: false,
  search: null,
  total: 0,
  all: [],
  offset: 0,
  limit: 10,
  totalPrograms: 0,
  totalSessions: 0,
  totalTime: 0,
};

type ActivityPayload = {
  search?: string;
  offset?: number;
  data?: {
    all: Activity[];
    totalSessions?: number;
    totalPrograms?: number;
    totalTime?: number;
    total: number;
  };
};

export default function Index(state = initialState, action: Action<ActivityPayload>) {
  switch (action.type) {
    case FETCH_ACTIVITIES.request: {
      const all = action.payload && action.payload.offset ? state?.all : initialState?.all;
      const isSearchCleared = state?.search && action?.payload && !action.payload?.search;
      const initialData =
        action.payload?.offset || action.payload?.search || isSearchCleared ? state : initialState;

      return {
        ...initialData,
        all,
        search: action.payload?.search ?? '',
      };
    }

    case FETCH_ACTIVITIES.success: {
      const receivedItems = action?.payload?.data?.all ?? [];

      return {
        ...initialState,
        search: action.payload?.search,
        total: action?.payload?.data?.total ?? 0,
        offset: action.payload?.offset ?? 0,
        totalPrograms: action?.payload?.data?.totalPrograms ?? 0,
        totalSessions: action?.payload?.data?.totalSessions ?? 0,
        totalTime: action?.payload?.data?.totalTime ?? 0,
        all: [...state.all, ...receivedItems],
      };
    }

    case FETCH_ACTIVITIES.failure: {
      return {
        ...initialState,
        ...state,
        error: true,
        all: [],
      };
    }

    default:
      return state;
  }
}

export const getTotalSessions = (state: StoreType) => state.activities.totalSessions;

export const getTotalTime = (state: StoreType) => state.activities.totalTime;
