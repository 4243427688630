import { Action, StoreType } from 'reducers/types';
import { USER_ACTION_TYPES } from 'actions/user';

type ErrorContainer = {
  [key: string]: {
    errorMessage: string;
  };
};

export interface UserState {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  username?: string | null;
  picture?: string | null;
  cookies?: string | null;
  error?: ErrorContainer | null;
  apAccess?: boolean;
  loading?: boolean;
  updating?: boolean;
  settings?: {
    reminder: boolean;
    timeZone: number;
    timeZoneText: string;
    videoCaptions: boolean;
    weeklyReminder: boolean;
  } | null;
  questionnaireComplete?: boolean;
}

const initialState: UserState = {
  id: null,
  firstName: null,
  lastName: null,
  email: null,
  username: null,
  picture: null,
  cookies: null,
  error: null,
  settings: null,
  apAccess: false,
  loading: false,
  updating: false,
  questionnaireComplete: false,
};

export default function Index(state = initialState, action: Action<UserState>) {
  switch (action.type) {
    case USER_ACTION_TYPES.REQUEST:
      return {
        ...initialState,
        id: state.id,
        cookies: state.cookies,
        loading: true,
      };

    case USER_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        id: action.payload.id,
        firstName: action.payload.firstName || null,
        lastName: action.payload.lastName || null,
        email: action.payload.email || null,
        username: action.payload.username || null,
        picture: action.payload.picture || null,
        cookies: action.payload.cookies || state.cookies || null,
        questionnaireComplete: action.payload.questionnaireComplete || false,
        apAccess: action.payload.apAccess || false,
        settings: action.payload.settings || null,
        loading: false,
        error: null,
      };

    case USER_ACTION_TYPES.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case USER_ACTION_TYPES.UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      };

    case USER_ACTION_TYPES.UPDATE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        cookies: state.cookies,
        updating: false,
        error: null,
      };

    case USER_ACTION_TYPES.UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        error: action.payload.error,
      };

    default:
      return {
        ...state,
      };
  }
}

export const getUserCookies = (state: StoreType) => state.user?.cookies;

export const getUser = (state: StoreType) => state.user;

export const getErrorMessage = (state: StoreType, type: string) => {
  const failure = state.user ? state.user.error : null;
  return failure && failure[type] ? failure[type].errorMessage : null;
};

export const getIsUserCaption = (state: StoreType) => state.user?.settings?.videoCaptions;

export const getIsUserUpdating = (state: StoreType) => state.user?.updating;
