export enum USER_ACTION_TYPES {
  REQUEST = 'USER_FETCH_REQUEST',
  SUCCESS = 'USER_FETCH_SUCCESS',
  FAILURE = 'USER_FETCH_FAILURE',
  UPDATE_REQUEST = 'USER_UPDATE_REQUEST',
  UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS',
  UPDATE_FAILURE = 'USER_UPDATE_FAILURE',
}

export const CLEAR_ERROR = 'CLEAR_ERROR';

export type UserActionPayload = {
  id: number;
  cookies?: string;
  error?: string;
};

export const fetchUser = () => ({
  type: USER_ACTION_TYPES.REQUEST,
  payload: {},
});

export const fetchUserFailed = (payload: UserActionPayload) => ({
  type: USER_ACTION_TYPES.FAILURE,
  payload,
});

export const fetchUserSuccess = (payload: UserActionPayload) => ({
  type: USER_ACTION_TYPES.SUCCESS,
  payload,
});

export const updateUser = (payload: UserActionPayload) => ({
  type: USER_ACTION_TYPES.UPDATE_REQUEST,
  payload,
});

export const updateUserFailed = (payload: UserActionPayload) => ({
  type: USER_ACTION_TYPES.UPDATE_FAILURE,
  payload,
});

export const updateUserSuccess = (payload: UserActionPayload) => ({
  type: USER_ACTION_TYPES.UPDATE_SUCCESS,
  payload,
});

export const clearErrorMessage = () => ({
  type: CLEAR_ERROR,
});
