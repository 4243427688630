import { put, call, select } from 'redux-saga/effects';
import Router from 'next/router';
import find from 'lodash/find';
import { getProgramSession } from '../reducers/sessions';
import { getWorkoutSessionId } from '../reducers/workout';
import {
  receiveFetchWorkoutSessions,
  errorFetchWorkoutSessions,
  receiveFetchWorkoutSessionsProgram,
  failureFetchWorkoutSessionsProgram,
  receiveFetchUserWorkoutSessionById,
  failureFetchUserWorkoutSessionById,
  receiveCreateUserWorkout,
  failureCreateUserWorkout,
  failureDoneWorkout,
  receiveCreatePreparePhaseAnswer,
  failureCreatePreparePhaseAnswer,
  receiveDeleteUserWorkout,
  failureDeleteUserWorkout,
  receiveFetchResumeWorkout,
  failureFetchResumeWorkout,
  receiveFetchWorkoutById,
  receiveFetchWorkoutExercises,
  receiveFetchWorkoutSession,
  failureFetchWorkoutSession,
  receiveUpdateWorkoutSurvey,
  failureUpdateWorkoutSurvey,
} from '../actions/workout';
import {
  requestFetchSessionExercisesList,
  requestNextExercisePhase,
  receiveFetchSessionExercisesList,
  receiveFetchBasicSessionExercisesList,
} from '../actions/exercise';
import { receiveFetchAchievements } from '../actions/achievements';
import { fetchPrograms, fetchProgramsSuccess } from '../actions/programs';
import { hideModal, showModal } from '../actions/modal';
import { fetchErrorHandler } from '../actions/fetchErrorHandler';
import sortDaysOfWeek from '../utils/sortDaysOfWeek';
import api from '../utils/api';

// fetch all programs
export function* fetchUserWorkoutSessions(action) {
  try {
    const { payload: { limit, offset } = {} } = action;
    const { data } = yield call(api().get, `/users/workout/session`, {
      params: { limit, offset },
    });

    yield put(receiveFetchWorkoutSessions(data));
  } catch (error) {
    yield put(errorFetchWorkoutSessions('Something went wrong'));
    yield put(fetchErrorHandler(error.response, 'Error while fetching workout sessions'));
  }
}

// fetch program workout session
export function* fetchProgramWorkoutSession(action) {
  try {
    const { programId } = action.payload;
    // get current user

    const { data } = yield call(api().get, `/users/workout/session?program_id=${programId}`);

    yield put(receiveFetchWorkoutSessionsProgram(data));
  } catch (error) {
    yield put(failureFetchWorkoutSessionsProgram('Something went wrong'));
    yield put(fetchErrorHandler(error.response, 'Error while fetching program workout session'));
  }
}

// fetch program workout
export function* fetchUserWorkoutSessionById(action) {
  try {
    const { sessionId, completed } = action.payload || {};
    const urlString = completed
      ? `/users/workout/session?session_id=${sessionId}&completed=${completed}`
      : `/users/workout/session?session_id=${sessionId}`;

    const { data } = yield call(api().get, urlString);

    yield put(receiveFetchUserWorkoutSessionById(data));
  } catch (error) {
    yield put(failureFetchUserWorkoutSessionById('Something went wrong'));
    yield put(fetchErrorHandler(error.response, 'Error while fetching workout session'));
  }
}

// create user workout
export function* createUserWorkout(action) {
  try {
    const { sessionId, programId, timeLength } = action.payload;

    const { data } = yield call(api().post, `/users/workout/session`, {
      sessionId,
      timeLength,
      programId,
    });

    yield put(receiveCreateUserWorkout(data));
    yield put(
      requestFetchSessionExercisesList({
        sessionId,
        timeLength,
      }),
    );
  } catch (error) {
    yield put(failureCreateUserWorkout('Something went wrong'));
    yield put(fetchErrorHandler(error.response, 'Error while creating workout session'));
  }
}

// delete user workout
export function* deleteUserWorkout() {
  try {
    const workoutSessionId = yield select(getWorkoutSessionId);
    const { data } = yield call(api().delete, `users/workout/session/${workoutSessionId}`);

    yield put(receiveDeleteUserWorkout(data));
  } catch (error) {
    yield put(failureDeleteUserWorkout);
    yield put(fetchErrorHandler(error.response, 'Error while deleting workout session'));
  }
}

// done user workout
export function* doneWorkout(action) {
  try {
    const {
      ease,
      quality,
      ponder,
      programId,
      sessionDateTimeData,
      completedExercises,
      favorite,
      challenging,
      workout,
    } = action.payload;

    const workoutSessionId = yield select(getWorkoutSessionId);
    const session = yield select(getProgramSession);

    yield call(api().put, `/users/workout/session/${workoutSessionId}`, {
      workoutSurvey: {
        ease,
        quality,
        ponder,
        favorite,
        challenging,
      },
      programId,
    });

    // get program
    const { data: programs = {} } = yield call(api().get, `/users/programs`);
    const { userPrograms } = programs;
    yield fetchProgramsSuccess(userPrograms);
    const currentProgram = userPrograms.find(item => item.program.id === Number(programId));

    if (
      currentProgram.programComplete &&
      currentProgram.completedSessionsCount === currentProgram.program.sessionsCount
    ) {
      yield put(showModal('PROGRAM_COMPLETED', { programId: Number(programId) }));
      return;
    }

    const schedule = sortDaysOfWeek(currentProgram.schedule);

    const { data: achievements } = yield call(api().get, `/users/achievements`);

    const notViewedAchievements = achievements.filter(
      achievement => achievement.viewed === false && achievement.earnedAt,
    );

    yield put(receiveFetchAchievements(achievements));
    yield put(fetchPrograms({ forceUpdate: true }));

    yield put(
      showModal('SESSION_COMPLETED', {
        session,
        schedule,
        notViewedAchievements,
        ease,
        quality,
        ponder,
        program: currentProgram,
        sessionDateTimeData,
        completedExercises,
        workout,
      }),
    );
  } catch (error) {
    yield put(failureDoneWorkout('Something went wrong'));
    yield put(fetchErrorHandler(error.response, 'Error while finishing workout session'));
  }
}

export function* updateWorkoutSurvey(action) {
  try {
    const { id: workoutSessionId, workoutSurvey } = action.payload;
    yield call(api().put, `/users/workout/session/${workoutSessionId}/survey`, {
      ...workoutSurvey,
    });

    yield put(receiveUpdateWorkoutSurvey({ id: workoutSessionId, workoutSurvey }));
    yield put(hideModal());
  } catch (error) {
    yield put(failureUpdateWorkoutSurvey(action.payload));
    yield put(hideModal());
  }
}

// create phase answer
export function* createPreparePhaseAnswer(action) {
  try {
    const { preparationPhaseAnswer, sessionId, isBasic, timeOption, programId } = action.payload;

    // get current user
    const workoutSessionId = yield select(getWorkoutSessionId);

    const { data } = yield call(api().put, `/users/workout/session/${workoutSessionId}`, {
      preparationPhaseAnswer,
      programId,
    });

    // if we have basic session and prepartion answer that user is tired then we grab the basic session exercises and add them to the exercise list
    if (isBasic && preparationPhaseAnswer === 2) {
      const exercisesURL = `/sessions/${sessionId}/session-exercises?phase=2,3,4&timeOption=${timeOption}&isBasic=true`;
      const { data: sessionExercises } = yield call(api().get, exercisesURL);

      yield put(
        receiveFetchBasicSessionExercisesList({
          timeLength: timeOption,
          sessionExercises,
        }),
      );
    } else {
      const exercisesURL = `/sessions/${sessionId}/session-exercises?phase=2,3,4&timeOption=${timeOption}`;
      const { data: sessionExercises } = yield call(api().get, exercisesURL);

      yield put(
        receiveFetchSessionExercisesList({
          timeLength: timeOption,
          sessionExercises,
        }),
      );
    }

    yield put(receiveCreatePreparePhaseAnswer(data));
    yield put(requestNextExercisePhase());
  } catch (error) {
    yield put(fetchErrorHandler(error.response, 'Something went wrong'));
    yield put(failureCreatePreparePhaseAnswer(`Something went wrong: ${error}`));
  }
}

// fetch program workout session
export function* fetchResumeWorkout(action) {
  try {
    const { sessionId, programId, redirect = true } = action.payload || {};

    // get all session workouts by session id
    const { data: workouts } = yield call(
      api().get,
      `users/workout/session?session_id=${sessionId}&completed=false`,
    );

    // save it to the store
    yield put(receiveFetchWorkoutSessions(workouts));
    // get the first workout session
    const { sessions = [] } = workouts || {};
    const workoutSession = sessions[0];

    if (workoutSession) {
      // get workout details by workout session id
      const { data: workoutExercise } = yield call(
        api().get,
        `users/workout/session/${workoutSession.id}`,
      );

      // save it to the store
      yield put(receiveFetchWorkoutById(workoutExercise));
      // get time length, preparation phase answer from workout session details entity
      const { timeLength } = workoutSession;
      // get workout exercises
      const { workoutExercises = [] } = workoutExercise || {};
      // save the workout exercises to the store
      yield put(receiveFetchWorkoutExercises(workoutExercises));
      // get last completed exercise from workout session exercises list
      const lastWorkoutExercise =
        find(workoutExercises, ['completed', false]) ||
        workoutExercises[workoutExercises.length - 1];

      const exercisesURL = `/sessions/${sessionId}/session-exercises?phase=1,2,3,4&timeOption=${timeLength}`;
      const { data: sessionExercises } = yield call(api().get, exercisesURL);

      yield put(
        receiveFetchSessionExercisesList({
          timeLength,
          sessionExercises,
          sessionId,
        }),
      );
      // save lastWorkoutExercise with all necessary data (workoutSessionId, exerciseId) to the store
      yield put(receiveFetchResumeWorkout(lastWorkoutExercise));

      if (redirect) {
        // redirect to current session
        Router.push(`/programs/${programId}/sessions/${sessionId}/start?resume=true`);
      }
      return;
    }
    // start session instead
    Router.push(`/programs/${programId}/sessions/${sessionId}/start`);
    return;
  } catch (error) {
    yield put(fetchErrorHandler(error.response, 'Something went wrong'));
    yield put(failureFetchResumeWorkout('Something went wrong'));
  }
}

export function* fetchWorkoutSession(action) {
  try {
    const { payload: { sessionId } = {} } = action;

    const { data } = yield call(api().get, `/users/workout/session?session_id=${sessionId}`);
    const { sessions = [] } = data || {};

    const workoutId = Number(new URL(document.location).searchParams.get('workoutId'));

    const workoutSession = sessions.find(i => i.id === workoutId);
    if (!workoutSession) {
      Router.push('/404');
      return;
    }
    if (workoutSession) {
      const { data: workout } = yield call(api().get, `users/workout/session/${workoutSession.id}`);
      yield put(receiveFetchWorkoutSession(workout));
    } else {
      yield put(failureFetchWorkoutSession('Something went wrong'));
    }
  } catch (error) {
    yield put(failureFetchWorkoutSession('Something went wrong'));
    yield put(fetchErrorHandler(error.response, 'Error while fetching workout session'));
  }
}
