import { receiveFetchActivities, failureFetchActivities } from 'actions/activities';
import { ActionType, ActivityResponse } from 'reducers/activities/types';
import { getUser } from 'reducers/user';
import { put, call, select } from 'redux-saga/effects';
import api from '../utils/api';

export default function* fetchActivities(action: ActionType) {
  try {
    const { payload } = action;
    const { search = '', limit = 10, offset = 0 } = payload || {};
    const { cookies } = yield select(getUser);

    const { data }: { data?: ActivityResponse } = yield call(api(cookies).get, `/activities/`, {
      params: {
        search,
        limit,
        offset,
      },
    });

    yield put(receiveFetchActivities({ search, offset, limit, data }));
  } catch (error) {
    yield put(failureFetchActivities());
  }
}
