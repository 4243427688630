import { ADVERTISEMENT_PRODUCT, ANNOUNCEMENT } from '../../actions/advertisement';

const initialState = {
  products: null,
  announcement: null,
};

export default function Index(state = initialState, action) {
  switch (action.type) {
    case ADVERTISEMENT_PRODUCT.success: {
      return {
        ...state,
        products: action.payload.products,
      };
    }
    case ANNOUNCEMENT.success: {
      return {
        ...state,
        announcement: action.payload.announcement,
      };
    }
    default:
      return state;
  }
}

export const getAdvertisementProducts = state => state.advertisement.products;
export const getAnnouncement = state => state.advertisement.announcement;
