import { SHOW_MODAL, HIDE_MODAL } from '../../actions/modal';

const initialState = {
  modalType: null,
  modalData: {},
};

export default function Index(state = initialState, action) {
  switch (action.type) {
    case SHOW_MODAL.success:
      return {
        modalType: action.payload.modal,
        modalData: action.payload.modalData,
      };
    case HIDE_MODAL.success:
      return initialState;
    default:
      return state;
  }
}
