import _ from 'lodash';

import { FETCH_BLOG_POSTS } from '../../actions/community';

const initialState = {
  posts: [],
};

export default function Index(state = initialState, action) {
  switch (action.type) {
    case FETCH_BLOG_POSTS.success: {
      const { data: dataPosts, postsImages } = action.payload;

      const posts = dataPosts
        ? dataPosts.map(post => {
            const foundPost = _.find(postsImages, ['id', post.featured_media]);

            return {
              ...post,
              featuredPicture: foundPost
                ? foundPost.source_url
                : 'https://gmb.io/wp-content/uploads/2020/06/Make-stretching-work-hero-desktop-1920x800.png',
            };
          })
        : null;

      return {
        posts,
      };
    }

    default:
      return state;
  }
}

// get blog posts
export const getBlogPosts = state => state.community.posts;
