import styled, { keyframes } from 'styled-components';
import colors from '../../constants/colors';

const bounce = keyframes`
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
`;

export const SpinnerElement = styled.div``;

export const Spinner = styled.div`
  margin: 0 auto;
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > ${SpinnerElement} {
    width: 12px;
    height: 12px;
    background-color: ${colors.main.lightBlue};

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: ${bounce} 1.4s infinite ease-in-out both;
    animation: ${bounce} 1.4s infinite ease-in-out both;

    &:nth-of-type(1) {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    &:nth-of-type(2) {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }

    &:nth-of-type(3) {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }
  }
`;
