import { call, select, put } from 'redux-saga/effects';
import Router from 'next/router';
import * as Sentry from '@sentry/nextjs';
import TagManager from 'react-gtm-module';
import getStripe from '../utils/getStripe';
import api from '../utils/api';
import { showModal } from '../actions/modal';
import { getUser, getUserCookies } from '../reducers/user';
import { fetchErrorHandler } from '../actions/fetchErrorHandler';

export function* redirectToStripeCheckout(action) {
  try {
    const {
      payload: { productId, isUpsell },
    } = action;

    const {
      data: { sessionId },
    } = yield call(api().post, `/products/${productId}/buy`, { isUpsell });

    const stripe = yield getStripe();
    const result = yield stripe.redirectToCheckout({ sessionId });

    if (result.error) {
      // we display this message to user because it's stripe error
      Sentry.captureException(result.error);
      yield put(showModal('PAYMENT_ERROR', { error: result.error.message }));
    }
  } catch (error) {
    yield put(fetchErrorHandler(error.response));

    if (error?.response?.data?.message === 'Product not found.') {
      Router.push('/404');
      return;
    }

    const errorMessage =
      error?.response?.data?.message ?? "Whoops, that didn't work. Let's try again from the start.";
    yield put(showModal('PAYMENT_ERROR', { error: errorMessage }));
  }
}

export function* fetchPurchasedProduct(action) {
  try {
    const {
      payload: { productId },
    } = action;

    if (!Number.isInteger(Number(productId))) {
      // we not need log invalid request
      yield put(showModal('PAYMENT_ERROR', { error: 'Product not found' }));
      return;
    }

    const {
      data: { product, upsellProductId },
    } = yield call(api().get, `/products/${productId}`);

    const { firstName, lastName, email } = yield select(getUser);
    const { data: userProduct } = yield call(api().get, `/users/products/${productId}`);

    const fbArgs = {
      dataLayer: {
        event: 'purchase',
        ecommerce: {
          currencyCode: 'USD',
          purchase: {
            actionField: {
              id: userProduct.stripeOrderId,
              revenue: product.price,
              coupon: '',
            },
            products: [
              {
                name: product.name,
                id: product.id,
                price: product.price,
                quantity: 1,
              },
            ],
          },
        },
        customer_email: email,
        customer_first_name: firstName,
        customer_last_name: lastName,
        // GA Transaction
        transactionId: userProduct.stripeOrderId,
        transactionTotal: product.price,
        currency: 'USD',
        transactionProducts: [
          {
            id: product.id,
            name: product.name,
            price: product.price,
            quantity: 1,
          },
        ],
      },
    };

    const gaArgs = {
      dataLayer: {
        // GA Transaction
        event: 'transaction',
        transactionId: userProduct.stripeOrderId,
        transactionTotal: product.price,
        currency: 'USD',
        transactionProducts: [
          {
            sku: product.id,
            name: product.name,
            price: product.price,
            quantity: 1,
          },
        ],
      },
    };

    TagManager.dataLayer(fbArgs);
    TagManager.dataLayer(gaArgs);

    yield put(showModal('POST_PURCHASE', { product, upsellProductId }));
  } catch (error) {
    yield put(fetchErrorHandler(error.response, 'Something went wrong'));
  } finally {
    Router.replace('/');
  }
}

export function* openCustomerPortal() {
  try {
    const cookies = yield select(getUserCookies);
    const {
      data: { url },
    } = yield call(api(cookies).get, `/products/payment-info`);

    Router.push(url);
  } catch (error) {
    Sentry.captureException(error);
  }
}
