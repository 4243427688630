import { put, call } from 'redux-saga/effects';
import api from '../utils/api';
import { receiveFetchAchievements, failureFetchAchievements } from '../actions/achievements';
import { hideModal } from '../actions/modal';
import { fetchErrorHandler } from '../actions/fetchErrorHandler';

// fetch all achievements
export default function* fetchAchievements(action) {
  try {
    const { markAsViewed } = action.payload || {};

    const URL = markAsViewed ? `/users/achievements?markAsViewed=true` : `/users/achievements`;

    const { data } = yield call(api().get, URL);

    if (markAsViewed) {
      yield put(hideModal());
    }

    yield put(receiveFetchAchievements(data));
  } catch (error) {
    yield put(failureFetchAchievements('Something went wrong'));
    yield put(fetchErrorHandler(error.response, 'Something went wrong'));
  }
}
