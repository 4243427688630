const SPACES = [
  '0px',
  '4px',
  '8px',
  '12px',
  '16px',
  '24px',
  '32px',
  '48px',
  '64px',
  '96px',
  '128px',
  '192px',
  '256px',
  '384px',
  '512px',
  '640px',
  '768px',
];

const breakpoints = ['993px'];

const THEME = {
  space: SPACES,
  breakpoints,
};

export default THEME;
