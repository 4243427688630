export const REDIRECT_TO_STRIPE_CHECKOUT = {
  request: 'REDIRECT_TO_STRIPE_CHECKOUT_REQUEST',
};
export const GET_PRODUCT_DATA = {
  request: 'GET_PRODUCT_REQUEST',
};
export const GET_CUSTOMER_PORTAL_URL = {
  request: 'GET_CUSTOMER_PORTAL_URL',
};

export const redirectToStripeCheckout = ({ productId, isUpsell } = {}) => ({
  type: REDIRECT_TO_STRIPE_CHECKOUT.request,
  payload: {
    productId,
    isUpsell,
  },
});
export const getProductDataRequest = ({ productId } = {}) => ({
  type: GET_PRODUCT_DATA.request,
  payload: {
    productId,
  },
});

export const getCustomerPortalUrl = () => ({
  type: GET_CUSTOMER_PORTAL_URL.request,
});
