export * from './toasts';
export * from './ponder';

export const ACHIEVEMENT_TYPES = {
  1: 'Session',
  2: 'Week streak',
  3: 'Program',
  4: 'Forum',
  5: 'Profile',
  6: 'Workout rating',
  7: 'Prep',
};

export const FAQ_AP = {
  1: {
    title: 'YOUR PERSONAL TRAINING LOG',
    description:
      'Tracking and reflecting on your training helps you get more out of each session. Whether you log daily, weekly, or just whenever, the log area in the Alpha forum gives you a central place to track your progress.',
  },
  2: {
    title: 'GUIDANCE FROM GMB TRAINERS',
    description:
      'Need help adjusting a program to suit your crazy schedule? Getting stuck on a difficult exercise and want someone to check your form? Our staff of trainers bring diverse backgrounds and extensive knowledge to keep you moving forward every day.',
  },
  3: {
    title: 'FRIENDLY, SUPPORTIVE COMMUNITY',
    description:
      'You’re not alone. Alpha is a close-knit group where members can share their challenges and successes with others doing the same work. The friendships that begin here are absolute gold.',
  },
  4: {
    title: 'MEMBERS-ONLY CONTENT & EVENTS',
    description:
      'Special Q&As, interviews with industry experts, exclusive programs, special meetups… there’s a lot going on, but we help you keep up by highlighting the best new stuff in regular recaps and reviews.',
  },
};

export const ASSESSMENT_MESSAGE = {
  answers: {
    1: 'Feeling good, ready to rock!',
    2: 'Felt more tired than usual',
  },
  title:
    'Being aware of how your body is feeling today will improve your progress and help you choose: push harder or slow it down.',
};

export const FINISH_SCREENS = {
  1: {
    title: 'prepare',
    description: '',
  },
  2: {
    title: 'practice',
    description:
      'All right! Remember, the goal is to get just a little smoother with each move every time.',
  },
  3: {
    title: 'play',
    description:
      'Wasn’t that fun? Play is serious business because it teaches you resilience and adaptability. Next we’ll simplify the moves and push you a bit harder.',
  },
  4: {
    title: 'push',
    description: '',
  },
};

export const INTRO_SCREENS = {
  1: {
    title: 'prepare',
    description:
      "First, we'll prepare your body for the training ahead. Start easy and get focused for today's work. This is also a chance to assess how you're feeling and where your energy level's at, so we'll check in at the end of this phase and see what kind of session is right for you today.",
  },
  2: {
    title: 'practice',
    description:
      "Practice new or challenging skills while you're fresh. For now, quality beats intensity. You'll have a few minutes to focus on technique and learn the details for each movement. Refer to the videos as often as you need, but don't worry if you can't do it perfectly. Just focus on the key points, and you'll get better.",
  },
  3: {
    title: 'play',
    description:
      "Now it's time to explore a bit. We'll take a move and play with a variation or combination of it. This is all about having some fun! So keep it relaxed and stay mindful of how your body feels.",
  },
  4: {
    title: 'push',
    description:
      "Now for the hard work! We're gonna bring the complexity down and the intensity up. Don't worry about how many reps you get in. Just keep going with the best quality you can. If your form slips, slow it down. If it feels solid, keep moving.",
  },
};

export const MUX_STREAM_URL = 'https://stream.mux.com';

export const AWS_VIDEO_ON_DEMAND_URL =
  'https://s3.amazonaws.com/video-on-demand-source-cezsh4obljae';

export const REST_VIDEO_PLAYBACK_ID = 'jomDuU01tUKZu6yyDFBnWoC2ezK2DmNskOSk9g2WewuM';

export const REST_VIDEO_ID = 'ad7192fb-fff0-4b0e-831b-5a80025e3726';

export const COMPLETE_STATUS = {
  empty: 1,
  started: 2,
  completed: 3,
};

export const DAYS_OF_WEEK = {
  mon: 1,
  tue: 2,
  wed: 3,
  thu: 4,
  fri: 5,
  sat: 6,
  sun: 7,
};

export const PHASES = {
  1: 'prepare',
  2: 'practice',
  3: 'play',
  4: 'push',
};

export const PHASES_NAMES = {
  1: 'Prep',
  2: 'Practice',
  3: 'Play',
  4: 'Push',
};

export const FRAMEWORK_5P = {
  '01': {
    name: 'prepare',
    description: 'Check in with your physical and mental state while getting warmed up.',
  },
  '02': {
    name: 'practice',
    description: 'Work on the technical details that make each movement safe and effective.',
  },
  '03': {
    name: 'play',
    description: 'Explore variations and combinations to build agility and adaptability.',
  },
  '04': {
    name: 'push',
    description: 'Ramp up the intensity to build your strength and endurance.',
  },
  '05': {
    name: 'ponder',
    description: 'Reflect on your session and rate your performance for consistent progress.',
  },
};

export const PROGRAM_DEMO_FOR_USER = {
  programs: {
    items: [
      {
        program: {
          id: 588,
          name: 'Elements',
          price: 95,
          picture: 'https://gmb.io/wp-content/uploads/2018/01/e-cover1-3.jpg',
          description:
            'Elements is an 8-week online training program that uses locomotive exercises and targeted mobility \nwork to build your strength, flexibility, and motor control.',
          equipment: 1,
          allowedTags: [],
          forbiddenTags: [],
          isDefault: true,
          complexity: 1,
          createdAt: '2018-11-16T12:03:01.143Z',
          updatedAt: '2018-11-16T12:03:01.143Z',
          sessionsCount: 2,
          workoutsCount: 0,
          completedSessionsCount: 0,
        },
        schedule: null,
      },
    ],
  },
  activities: [
    {
      id: 1,
      startedAt: '2018-11-29T10:25:53.000Z',
      completedAt: '2018-11-29T11:32:00.766Z',
      exercisesCount: 2,
      workoutSurvey: {
        ease: 4,
        ponder: '',
        quality: 3,
      },
      preparationPhaseAnswer: 1,
      completedPhases: [1, 2],
      timeLength: 45,
      session: {
        id: 162,
        name: 'Prepare',
        description:
          'Assess your current starting point with the preparatory, fundamental, and stretching movements',
        order: 1,
        bullets: [
          'Feel free to follow along with the videos for now, but as you become more familiar you won’t need to. And also you’ll find yourself going through them at different rates of speed because you each training session may be different.',
          'This is why we recommend a broad range of repetitions, with a minimum of 5 and a max of 20. Some days you’ll need more time to warm up, while others you’ll just need a quick run through to prepare.',
          'As you practice you’ll gain more awareness of exactly what you need, instead of just following an arbitrary amount of repetitions.',
        ],
        program: {
          description:
            'Elements is an 8-week online training program that uses locomotive exercises and targeted mobility \nwork to build your strength, flexibility, and motor control.',
          equipment: 1,
          complexity: 1,
          allowedTags: [],
          forbiddenTags: [],
          isDefault: true,
          id: 588,
          name: 'Elements',
          price: 95,
          picture: 'https://gmb.io/wp-content/uploads/2018/01/e-cover1-3.jpg',
          createdAt: '2018-11-16T12:03:01.143Z',
          updatedAt: '2018-11-16T12:03:01.143Z',
        },
        picture: null,
      },
    },
    {
      id: 2,
      startedAt: '2018-12-03T10:25:53.000Z',
      completedAt: '2018-12-03T11:32:00.766Z',
      exercisesCount: 5,
      workoutSurvey: {
        ease: 4,
        ponder: '',
        quality: 3,
      },
      preparationPhaseAnswer: 1,
      completedPhases: [1, 2],
      timeLength: 14,
      session: {
        id: 162,
        name: 'Prepare',
        description:
          'Assess your current starting point with the preparatory, fundamental, and stretching movements',
        order: 3,
        bullets: [
          'Feel free to follow along with the videos for now, but as you become more familiar you won’t need to. And also you’ll find yourself going through them at different rates of speed because you each training session may be different.',
          'This is why we recommend a broad range of repetitions, with a minimum of 5 and a max of 20. Some days you’ll need more time to warm up, while others you’ll just need a quick run through to prepare.',
          'As you practice you’ll gain more awareness of exactly what you need, instead of just following an arbitrary amount of repetitions.',
        ],
        program: {
          description:
            'Elements is an 8-week online training program that uses locomotive exercises and targeted mobility \nwork to build your strength, flexibility, and motor control.',
          equipment: 1,
          complexity: 1,
          allowedTags: [],
          forbiddenTags: [],
          isDefault: true,
          id: 588,
          name: 'VITAMIN',
          price: 95,
          picture: 'https://gmb.io/wp-content/uploads/2018/01/e-cover1-3.jpg',
          createdAt: '2018-11-16T12:03:01.143Z',
          updatedAt: '2018-11-16T12:03:01.143Z',
        },
        picture: null,
      },
    },
    {
      id: 3,
      startedAt: '2018-12-01T10:25:53.000Z',
      completedAt: '2018-12-01T10:32:00.766Z',
      exercisesCount: 3,
      workoutSurvey: {
        ease: 5,
        ponder: '',
        quality: 4,
      },
      preparationPhaseAnswer: 1,
      completedPhases: [1, 2],
      timeLength: 30,
      session: {
        id: 162,
        name: 'Prepare',
        description:
          'Assess your current starting point with the preparatory, fundamental, and stretching movements',
        order: 2,
        bullets: [
          'Feel free to follow along with the videos for now, but as you become more familiar you won’t need to. And also you’ll find yourself going through them at different rates of speed because you each training session may be different.',
          'This is why we recommend a broad range of repetitions, with a minimum of 5 and a max of 20. Some days you’ll need more time to warm up, while others you’ll just need a quick run through to prepare.',
          'As you practice you’ll gain more awareness of exactly what you need, instead of just following an arbitrary amount of repetitions.',
        ],
        program: {
          description:
            'Elements is an 8-week online training program that uses locomotive exercises and targeted mobility \nwork to build your strength, flexibility, and motor control.',
          equipment: 1,
          complexity: 1,
          allowedTags: [],
          forbiddenTags: [],
          isDefault: true,
          id: 588,
          name: 'INTEGRAL STRENGTH',
          price: 95,
          picture: 'https://gmb.io/wp-content/uploads/2018/01/e-cover1-3.jpg',
          createdAt: '2018-11-16T12:03:01.143Z',
          updatedAt: '2018-11-16T12:03:01.143Z',
        },
        picture: null,
      },
    },
    {
      id: 4,
      startedAt: '2018-12-05T10:25:53.000Z',
      completedAt: '2018-12-05T11:32:00.766Z',
      exercisesCount: 4,
      workoutSurvey: {
        ease: 2,
        ponder: '',
        quality: 1,
      },
      preparationPhaseAnswer: 1,
      completedPhases: [1, 2],
      timeLength: 18,
      session: {
        id: 162,
        name: 'Prepare',
        description:
          'Assess your current starting point with the preparatory, fundamental, and stretching movements',
        order: 1,
        bullets: [
          'Feel free to follow along with the videos for now, but as you become more familiar you won’t need to. And also you’ll find yourself going through them at different rates of speed because you each training session may be different.',
          'This is why we recommend a broad range of repetitions, with a minimum of 5 and a max of 20. Some days you’ll need more time to warm up, while others you’ll just need a quick run through to prepare.',
          'As you practice you’ll gain more awareness of exactly what you need, instead of just following an arbitrary amount of repetitions.',
        ],
        program: {
          description:
            'Elements is an 8-week online training program that uses locomotive exercises and targeted mobility \nwork to build your strength, flexibility, and motor control.',
          equipment: 1,
          complexity: 1,
          allowedTags: [],
          forbiddenTags: [],
          isDefault: true,
          id: 588,
          name: 'ELEMENTS',
          price: 95,
          picture: 'https://gmb.io/wp-content/uploads/2018/01/e-cover1-3.jpg',
          createdAt: '2018-11-16T12:03:01.143Z',
          updatedAt: '2018-11-16T12:03:01.143Z',
        },
        picture: null,
      },
    },
  ],
};

export { default as TIMEZONES } from './timezones';

export const DATE_FORMAT = `DD MMM'YY`;

export const OUTDATED = 'outdated';

export const SESSION_STATE_KEY = 'app-session-state';

export const FPS_VIDEO = {
  originalName: 'GUAC 5Ps.mp4',
  fileName: '48ad4b20-cbad-11ec-ab8d-514919d92558.mp4',
  url: `${MUX_STREAM_URL}/EklW9MJzpMsRlh7KC3UcWxq73y02X3S6vkuN381IFd01U/high.mp4`,
  subtitlesUrl: '0cfe9c9b-bcd3-4f58-85c0-8bcc36f72ef4.vtt',
  hlsUrl: `${MUX_STREAM_URL}/EklW9MJzpMsRlh7KC3UcWxq73y02X3S6vkuN381IFd01U.m3u8`,
};
