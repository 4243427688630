import getConfig from 'next/config';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { createWrapper } from 'next-redux-wrapper';
import sagasRoot from '../sagas';
import reducersRoot from '../reducers';
import { loadFromLocalStorage, saveToLocalStorage } from '../utils/storeUtils';

const { publicRuntimeConfig } = getConfig();
const { reduxLoggingEnabled } = publicRuntimeConfig;
const logger = createLogger({});

const bindMiddleware = middleware => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line global-require
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const initializeStore = initialState => {
  const sagaMiddleware = createSagaMiddleware();
  // redux middlewares
  const middlewares = [sagaMiddleware];
  if (reduxLoggingEnabled) {
    middlewares.push(logger);
  }
  const store = createStore(
    reducersRoot,
    {
      ...initialState,
      ...loadFromLocalStorage(),
    },
    bindMiddleware(middlewares),
  );

  store.subscribe(() => saveToLocalStorage(store.getState()));

  store.sagaTask = sagaMiddleware.run(sagasRoot);
  return store;
};

const wrapper = createWrapper(initializeStore, { debug: reduxLoggingEnabled });

export default wrapper;
