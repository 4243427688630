// FETCH PROGRAMS SESSIONS
export const FETCH_PROGRAM_SESSIONS = {
  request: 'FETCH_PROGRAM_SESSIONS_REQUEST',
  success: 'FETCH_PROGRAM_SESSIONS_SUCCESS',
  failure: 'FETCH_PROGRAM_SESSIONS_FAILURE',
};

export const requestFetchProgramSessions = () => ({
  type: FETCH_PROGRAM_SESSIONS.request,
});

export const receiveFetchProgramSessions = payload => ({
  type: FETCH_PROGRAM_SESSIONS.success,
  payload,
});

export const failureFetchProgramSessions = payload => ({
  type: FETCH_PROGRAM_SESSIONS.failure,
  payload,
});

// FETCH PROGRAMS SESSIONS BY SESSION ID
export const FETCH_PROGRAM_SESSION_BY_ID = {
  request: 'FETCH_PROGRAM_SESSION_BY_ID_REQUEST',
  success: 'FETCH_PROGRAM_SESSION_BY_ID_SUCCESS',
  failure: 'FETCH_PROGRAM_SESSION_BY_ID_FAILURE',
};

export const requestFetchProgramSessionById = payload => ({
  type: FETCH_PROGRAM_SESSION_BY_ID.request,
  payload,
});

export const receiveFetchProgramSessionById = payload => ({
  type: FETCH_PROGRAM_SESSION_BY_ID.success,
  payload,
});

export const failureFetchProgramSessionById = payload => ({
  type: FETCH_PROGRAM_SESSION_BY_ID.failure,
  payload,
});

export const FETCH_EXPANDED_SESSION_INFO = {
  request: 'FETCH_EXPANDED_SESSION_INFO_REQUEST',
  success: 'FETCH_EXPANDED_SESSION_INFO_SUCCESS',
  failure: 'FETCH_EXPANDED_SESSION_INFO_FAILURE',
};

export const requestFetchExpandedSessionInfo = payload => ({
  type: FETCH_EXPANDED_SESSION_INFO.request,
  payload,
});

export const successFetchExpandedSessionInfo = payload => ({
  type: FETCH_EXPANDED_SESSION_INFO.success,
  payload,
});

export const failureFetchExpandedSessionInfo = payload => ({
  type: FETCH_EXPANDED_SESSION_INFO.failure,
  payload,
});
