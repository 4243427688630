import { CREATE_FEEDBACK } from '../../actions/feedback';

const initialState = {
  success: null,
  error: null,
};

export default function Index(state = initialState, action) {
  switch (action.type) {
    case CREATE_FEEDBACK.success:
      return {
        success: true,
      };
    default:
      return state;
  }
}

export const getError = state => state.feedback.error;
