export const SAVE_PONDER_BY_SESSION = 'SAVE_PONDER_BY_SESSION';

export const CLEAR_PONDER_ALL = 'CLEAR_PONDER_ALL';

export const savePonder = (sessionId, ponder) => ({
  type: SAVE_PONDER_BY_SESSION,
  payload: {
    sessionId,
    ponder,
  },
});

export const clearPonder = () => ({
  type: CLEAR_PONDER_ALL,
});
