export const CREATE_FEEDBACK = {
  request: 'CREATE_FEEDBACK_REQUEST',
  success: 'CREATE_FEEDBACK_SUCCESS',
  failure: 'CREATE_FEEDBACK_FAILURE',
};

export const requestCreateFeedback = payload => ({
  type: CREATE_FEEDBACK.request,
  payload,
});

export const receiveCreateFeedback = payload => ({
  type: CREATE_FEEDBACK.success,
  payload,
});

export const errorCreateFeedback = payload => ({
  type: CREATE_FEEDBACK.failure,
  payload,
});
