import styled, { css } from 'styled-components';
import Button from '../Button';
import colors from '../../constants/colors';

export const ToastContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 3px;
`;

export const StyledButton = styled(Button)`
  height: 40px;
  min-width: 110px;
  max-width: 110px;

  ${props =>
    props.hidden &&
    css`
      background: #ccc;
      box-shadow: none;
    `}

  ${props =>
    props.reload &&
    css`
      margin-bottom: 5px;
    `}
`;

export const ToastDefaultStyles = {
  background: colors.main.white,
  color: colors.main.black,
  zIndex: 999999,
  boxShadow: '0 3px 10px rgb(0 0 0 / 10%), 0 3px 3px rgb(0 0 0 / 30%)',
};
