export const ADVERTISEMENT_PRODUCT = {
  request: 'ADVERTISEMENT_PRODUCT_REQUEST',
  success: 'ADVERTISEMENT_PRODUCT_SUCCESS',
};

export const ANNOUNCEMENT = {
  request: 'ANNOUNCEMENT_REQUEST',
  success: 'ANNOUNCEMENT_SUCCESS',
};

export const requestAdvertisementProduct = () => ({
  type: ADVERTISEMENT_PRODUCT.request,
});
export const receiveAdvertisementProduct = ({ products }) => ({
  type: ADVERTISEMENT_PRODUCT.success,
  payload: { products },
});

export const requestAnnouncement = () => ({
  type: ANNOUNCEMENT.request,
});
export const receiveAnnouncement = ({ announcement }) => ({
  type: ANNOUNCEMENT.success,
  payload: { announcement },
});
