import { put, call, select } from 'redux-saga/effects';
import { fetchUserSuccess, updateUserFailed, updateUserSuccess } from 'actions/user';
import api from '../utils/api';
import { identify } from '../utils/helpscout';
import { getUser } from '../reducers/user';
import { showModal } from '../actions/modal';
import { receiveFetchAchievements } from '../actions/achievements';
import { fetchErrorHandler } from '../actions/fetchErrorHandler';

const URL = '/users';

export function* fetchUser(action) {
  try {
    const { id } = yield select(getUser);
    const { data: user } = yield call(api().get, `${URL}/${id || 'me'}`);
    // default settings for notifications
    const defaultSettings = {
      videoCaptions: true,
      reminder: false,
      muxPlayback: false,
    };

    const initUserWithSettings = {
      ...user,
      settings: {
        ...defaultSettings,
        ...user.settings,
      },
    };

    yield put(fetchUserSuccess(initUserWithSettings));

    if (action.payload?.identify) {
      identify(user);
    }
  } catch (error) {
    yield put(fetchErrorHandler(error.response, 'Something went wrong'));
  }
}

export function* updateUser(action) {
  try {
    const { data: user, modal } = action.payload;
    const { id } = yield select(getUser);

    let picture = null;

    // if image exists then update user picture
    if (user.file) {
      const { data } = yield call(api().post, '/file', user.file, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });

      picture = data.fileName;
    }

    delete user.file;

    const userDetails = picture ? { ...user, picture } : { ...user };

    const { data } = yield call(api().put, `${URL}/${id || 'me'}`, userDetails);
    yield put(updateUserSuccess(data));

    const { data: achievements } = yield call(api().get, `/users/achievements`);

    yield put(receiveFetchAchievements(achievements));
    if (modal) {
      yield put(showModal(modal.type, modal.data));
    }
  } catch (error) {
    yield put(fetchErrorHandler(error.response, 'Something went wrong'));
    const errorMessage =
      typeof error?.response?.data?.message === 'string'
        ? error.response.data.message
        : 'Something went wrong';
    yield put(updateUserFailed(errorMessage));
  }
}
