/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import { SpaceProps } from 'styled-system';
import { TextProps } from './types';
import StyledTypography from './styles';

type Props = Partial<TextProps & SpaceProps> & {
  children: React.ReactNode;
};

const Text: FC<Props> = ({
  variant = 'text',
  tag,
  color = 'currentColor',
  transform,
  weight,
  align,
  isCondensed = false,
  className,
  isResponsive = false,
  withEllipsis = false,
  withShadow = false,
  children,
  ...props
}) => (
  <StyledTypography
    variant={variant}
    tag={tag}
    className={className}
    color={color}
    transform={transform}
    weight={weight}
    align={align}
    isCondensed={isCondensed}
    isResponsive={isResponsive}
    withEllipsis={withEllipsis}
    withShadow={withShadow}
    {...props}
  >
    {children}
  </StyledTypography>
);

export default Text;
