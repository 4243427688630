import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { createGlobalStyle } from 'styled-components';
import Router, { useRouter } from 'next/router';
import { isServer } from 'utils/getEnvironment';
import animate from 'animate.css';
import normalize from 'sanitize.css';
import toggler from 'react-toggle/style.css';
import rcslider from 'rc-slider/assets/index.css';
import commoncss from '../src/assets/styles/common.css';
import slickSlider from '../src/assets/styles/slick-slider.css';
import wrapper from '../src/store';
import Toast from '../src/components/Toast';
import NProgress from '../src/components/NProgress';
import '../sentry.client.config';
import * as SuperTokensConfig from '../config/frontendConfig';
import SuperTokensReact, { SuperTokensWrapper, redirectToAuth } from 'supertokens-auth-react';
import Session from 'supertokens-auth-react/recipe/session';
import { SpeedInsights } from '@vercel/speed-insights/react';

export const GlobalStyles = createGlobalStyle([
  animate,
  normalize,
  commoncss,
  toggler,
  rcslider,
  slickSlider,
]);

if (!isServer()) {
  // we only want to call this init function on the frontend, so we check typeof window !== 'undefined'
  SuperTokensReact.init(SuperTokensConfig.frontendConfig());
}

const Root = ({ Component, ...rest }) => {
  const { store, props } = wrapper.useWrappedStore(rest);
  const { pageProps, err } = props;
  const router = useRouter();

  useEffect(() => {
    async function doRefresh() {
      if (pageProps.fromSupertokens === 'needs-refresh') {
        if (await Session.attemptRefreshingSession()) {
          location.reload();
        } else {
          // user has been logged out
          redirectToAuth();
        }
      }
    }
    doRefresh();
  }, [pageProps.fromSupertokens]);

  useEffect(() => {
    Router.events.on('routeChangeComplete', () => {
      window.scrollTo(0, 0);
    });
  }, []);

  if (pageProps.fromSupertokens === 'needs-refresh') {
    return null;
  }

  return (
    <Provider store={store}>
      <SuperTokensWrapper>
        <SpeedInsights route={router.pathname} />
        <GlobalStyles />
        <NProgress showAfterMs={300} options={{ trickleSpeed: 50 }} />
        <Toast />
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Component {...pageProps} err={err} />
      </SuperTokensWrapper>
    </Provider>
  );
};

export default Root;
