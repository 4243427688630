import { put, call, select } from 'redux-saga/effects';
import api from '../utils/api';
import { getUserCookies } from '../reducers/user';
import { showModal } from '../actions/modal';
import { errorCreateFeedback } from '../actions/feedback';
import { fetchErrorHandler } from '../actions/fetchErrorHandler';

export default function* createFeedback(action) {
  try {
    const { feedback, resetForm } = action.payload;

    const accessToken = yield select(getUserCookies);

    yield call(api(accessToken).post, `/feedback`, {
      ...feedback,
    });
    yield resetForm();
    yield put(
      showModal('SUCCESS_FEEDBACK', {
        title: 'Got it!',
        description:
          feedback.type === 1 ? "We'll get back to you soon!" : "We'll get back to you soon!",
      }),
    );
  } catch (error) {
    yield put(fetchErrorHandler(error.response, 'Something went wrong'));
    error.response.data.message.forEach(msg => {
      action.payload.setErrors({ [msg.field]: msg.errors.join(',') });
    });

    yield put(errorCreateFeedback(error.response.data.message || 'Something went wrong'));
  }
}
