import findIndex from 'lodash/findIndex';
import memoize from 'lodash/memoize';
import uniq from 'lodash/uniq';
import { createSelector } from 'reselect';

import {
  FETCH_SESSION_EXERCISES_LIST,
  FETCH_BASIC_SESSION_EXERCISES_LIST,
  FETCH_SIGN_EXERCISE_VIDEO,
  GET_NEXT_EXERCISE,
  GET_PREV_EXERCISE,
  FETCH_EXERCISE_BY_ID,
  COMPLETE_EXERCISE,
  GET_NEXT_EXERCISE_PHASE,
  CLEAR_EXERCISES_STATE,
  SET_ASSESSMENT_SCREEN,
  SET_INTRO_SCREEN,
  CLEAR_EXERCISE_DETAILS,
} from '../../actions/exercise';

import { FETCH_RESUME_WORKOUT, CREATE_PREPARE_PHASE_ANSWER } from '../../actions/workout';

import { getWorkoutById } from '../workout';

const initialState = {
  all: {},
  exerciseId: null,
  currentSessionExerciseId: null,
  workoutExerciseId: null,
  exerciseDetails: null,
  exerciseDetailsLoading: false,
  isResumingSession: false,
  exerciseIdPhase: null,
  isAssessing: false,
  isAddingCard: false,
  // new state for consisting data where we left off
  assessmentScreen: false, // screen #1
  introScreen: true, // screen #2
  sessionExercises: [],
  sessionTimeLength: null,
};

const SESSION_EXERCISE_STATUS = {
  NOT_STARTED: 1,
  IN_PROGRESS: 2,
  COMPLETED: 3,
};

const DEFAULT_ROUND_NUMBER = 1;

const sessionExercisesSelector = state => state.sessionExercises || [];

const currentSessionExerciseIdSelector = state => state.currentSessionExerciseId;

/**
 * There are two types of session exercises: ordinary and circuit. A
 * circuit might contain ordinary session exercises in it. So we have
 * two-level tree. This function returns an iterator which allows to
 * walk through the given `sessionExercises` (tree) excluding the
 * session exercises of `circuit` type. Please note, the session
 * exercises in the iterator gets modified, so these session exercises
 * are not intended to be stored in the store.
 */
export function* makeSessionExercisesIterator(sessionExercises) {
  // eslint-disable-next-line no-restricted-syntax
  for (const sessionExercise of sessionExercises) {
    if (sessionExercise.type === 'circuit') {
      for (let i = 1; i <= sessionExercise.totalRoundsCount; i += 1) {
        const childSessionExercises = sessionExercise.sessionExercises;
        const childrenLength = childSessionExercises.length;
        for (let j = 0; j < childrenLength; j += 1) {
          const childSessionExercise = {
            ...childSessionExercises[j],
          };
          childSessionExercise.round = i;
          childSessionExercise.totalRoundsCount = sessionExercise.totalRoundsCount;
          const firstInRound = j === 0;
          childSessionExercise.firstInRound = firstInRound;
          childSessionExercise.firstInCircuit = firstInRound && i === 1;
          const lastInRound = j === childrenLength - 1;
          childSessionExercise.lastInRound = lastInRound;
          childSessionExercise.lastInCircuit =
            lastInRound && i === sessionExercise.totalRoundsCount;
          yield childSessionExercise;
        }
      }
    } else if (sessionExercise.type === 'ordinary') {
      yield sessionExercise;
    }
  }
}

export const getCurrentSessionExercise = createSelector(
  sessionExercisesSelector,
  currentSessionExerciseIdSelector,
  (sessionExercises, sessionExerciseId) => {
    let circuitId = null;
    const iterator = makeSessionExercisesIterator(sessionExercises);
    // eslint-disable-next-line no-restricted-syntax
    for (const sessionExercise of iterator) {
      if (sessionExercise.id === sessionExerciseId) {
        circuitId = sessionExercise.circuitId;
        break;
      }
    }
    if (circuitId) {
      const circuit = sessionExercises.find(sessionExercise => sessionExercise.id === circuitId);
      // eslint-disable-next-line no-param-reassign
      sessionExercises = circuit.sessionExercises;
    }
    const res = sessionExercises.find(sessionExercise => sessionExercise.id === sessionExerciseId);
    return res;
  },
);

const exerciseIdPhaseSelector = state => state.exerciseIdPhase;

export const getSessionExercisesInCurrentPhase = createSelector(
  sessionExercisesSelector,
  exerciseIdPhaseSelector,
  (sessionExercises, currentPhase) => {
    // eslint-disable-next-line eqeqeq
    return sessionExercises.filter(item => item.phase == currentPhase);
  },
);

const getCurrentCircuit = state => {
  const currentSessionExercise = getCurrentSessionExercise(state);
  const sessionExercises = getSessionExercisesInCurrentPhase(state);
  return sessionExercises.find(sessionExercise => {
    if (sessionExercise.id === currentSessionExercise.circuitId) {
      return sessionExercise;
    }
    return undefined;
  });
};

export const getCurrentCircuitRound = state => {
  const circuit = getCurrentCircuit(state);
  return circuit ? circuit.round : null;
};

export const getAvailablePhases = createSelector(sessionExercisesSelector, sessionExercises => {
  return uniq(sessionExercises.map(item => item.phase));
});

export const sessionExercisesByPhaseSelector = createSelector(
  sessionExercisesSelector,
  // eslint-disable-next-line eqeqeq
  sessionExercises => memoize(phase => sessionExercises.filter(item => item.phase == phase)),
);

export const getSessionExercisesByPhase = state => {
  return phase => {
    return sessionExercisesByPhaseSelector(state)(phase);
  };
};

export const getSessionExercises = state => {
  return sessionExercisesSelector(state);
};

export const getCircuitById = state => {
  const sessionExercises = getSessionExercisesInCurrentPhase(state);
  return circuitId => {
    // eslint-disable-next-line eqeqeq
    return sessionExercises.find(item => item.id == circuitId);
  };
};

// get exercise id
export const getCurrentExerciseId = state => state.exerciseId;

export const getCurrentSessionExerciseId = state => state.currentSessionExerciseId;

// get exercise id phase
export const getCurrentExerciseIdPhase = state => state.exerciseIdPhase;

/**
 * Get the first session exercise in the current phase.
 */
export const getFirstSessionExerciseInCurrentPhase = state => {
  const sessionExercises = getSessionExercisesInCurrentPhase(state);
  const iterator = makeSessionExercisesIterator(sessionExercises);
  // eslint-disable-next-line no-restricted-syntax
  for (const sessionExercise of iterator) {
    // Return the first session exercise. We cannot return
    // `sessionExercises[0]` since the first item in the list might be
    // a session exercise of `circuit` type, but we need a session
    // exercise of `ordinary` type. The `iterator` returns the session
    // exercises of `ordinary` type only.
    return sessionExercise;
  }
  return null;
};

export const isFirstSessionExerciseInCurrentPhase = state => {
  /**
   * Return `true` if the given `sessionExercise` is the first session
   * exercise in the current phase.
   */
  return sessionExercise => {
    const firstSessionExercise = getFirstSessionExerciseInCurrentPhase(state);
    if (firstSessionExercise.id === sessionExercise.id) {
      const { circuitId } = firstSessionExercise;
      if (circuitId) {
        // If the session exercise belongs to a circuit then check the
        // round of the circuit.
        const circuit = getCircuitById(state)(circuitId);
        if (circuit.round === 1) {
          return true;
        }
      } else {
        return true;
      }
    }
    return false;
  };
};

// get if all exercises completed (NOTE: reselector is forbidden here)
export const getIsAllExercisesCompleted = state => {
  const { sessionExercises } = state;
  return sessionExercises.every(sessionExercise => sessionExercise.completed);
};

/**
 * Update the round of the current circuit if needed and return its
 * value.
 */
const updateCircuitRound = (state, reversed) => {
  const currentSessionExercise = getCurrentSessionExercise(state);
  let circuitRound = getCurrentCircuitRound(state);

  const { sessionExercises } = state;
  const iterator = makeSessionExercisesIterator(sessionExercises);

  // eslint-disable-next-line no-restricted-syntax
  for (const sessionExercise of iterator) {
    if (sessionExercise.id === currentSessionExercise.id) {
      if (sessionExercise.circuitId) {
        if (sessionExercise.round === circuitRound) {
          if (reversed) {
            if (sessionExercise.firstInRound) {
              // This is the first session exercise in the current
              // circuit round.
              circuitRound -= 1;
            }
          } else if (sessionExercise.lastInRound) {
            // This is the last session exercise in the current
            // circuit round.
            circuitRound += 1;
          }
          break;
        }
      }
    }
  }
  return circuitRound;
};

// get if phase completed by active exercise
export const getIsPhaseCompletedByExercise = state => {
  const sessionExercises = getSessionExercisesInCurrentPhase(state);
  if (sessionExercises) {
    return sessionExercises.every(sessionExercise => sessionExercise.completed);
  }
  return null;
};

export const getExerciseDuration = state => {
  const { timeLength } = getWorkoutById(state) || {};
  const sessionExercise = getCurrentSessionExercise(state.exercises) || {};
  return timeLength && sessionExercise ? sessionExercise.timeMatrix[timeLength] : null;
};

export const getRestDuration = state => {
  const circuit = getCurrentCircuit(state);
  return circuit ? circuit.restFor : null;
};

export const isLastSessionExerciseInCurrentRound = state => {
  const currentSessionExercise = getCurrentSessionExercise(state);
  const circuit = getCurrentCircuit(state);
  if (circuit) {
    const sessionExercises = getSessionExercisesInCurrentPhase(state);
    const iterator = makeSessionExercisesIterator(sessionExercises);
    // eslint-disable-next-line no-restricted-syntax
    for (const sessionExercise of iterator) {
      if (
        sessionExercise.id === currentSessionExercise.id &&
        sessionExercise.round === circuit.round
      ) {
        return sessionExercise.lastInRound;
      }
    }
  }
  return undefined;
};

export const isLastSessionExerciseInCurrentCircuit = state => {
  const currentSessionExercise = getCurrentSessionExercise(state);
  const circuit = getCurrentCircuit(state);
  if (circuit) {
    const sessionExercises = getSessionExercisesInCurrentPhase(state);
    const iterator = makeSessionExercisesIterator(sessionExercises);
    // eslint-disable-next-line no-restricted-syntax
    for (const sessionExercise of iterator) {
      if (
        sessionExercise.id === currentSessionExercise.id &&
        sessionExercise.round === circuit.round
      ) {
        return sessionExercise.lastInCircuit;
      }
    }
  }
  return undefined;
};

// get is resuming session
export const getIsResumingSession = state => state.isResumingSession;

export const getIsAddingCard = state => state.isAddingCard;

export const getIsIntroScreen = state => state.introScreen;

const getNextSessionExercise = (state, round) => {
  // eslint-disable-next-line no-param-reassign
  round = round || DEFAULT_ROUND_NUMBER;
  const currentSessionExercise = getCurrentSessionExercise(state);
  const sessionExercises = getSessionExercisesInCurrentPhase(state);
  const iterator = makeSessionExercisesIterator(sessionExercises);

  let sessionExercise = null;
  // eslint-disable-next-line no-restricted-syntax
  for (const nextSessionExercise of iterator) {
    if (sessionExercise) {
      // eslint-disable-next-line eqeqeq
      if (sessionExercise.id == currentSessionExercise.id) {
        if (nextSessionExercise.circuitId) {
          if (nextSessionExercise.circuitId === currentSessionExercise.circuitId) {
            if (round === nextSessionExercise.round) {
              return nextSessionExercise;
            }
          } else {
            return nextSessionExercise;
          }
        } else {
          return nextSessionExercise;
        }
      }
    }
    sessionExercise = nextSessionExercise;
  }
  return undefined;
};

const getPreviousSessionExercise = (state, round) => {
  const currentSessionExercise = getCurrentSessionExercise(state);
  const sessionExercises = getSessionExercisesInCurrentPhase(state);
  const iterator = makeSessionExercisesIterator(sessionExercises);
  let previousSessionExercise = null;
  // eslint-disable-next-line no-restricted-syntax
  for (const sessionExercise of iterator) {
    // eslint-disable-next-line eqeqeq
    if (sessionExercise.id == currentSessionExercise.id) {
      if (sessionExercise.circuitId) {
        if (previousSessionExercise) {
          if (round === 0) {
            return previousSessionExercise;
          }
          if (round === previousSessionExercise.round) {
            return previousSessionExercise;
          }
        }
      } else {
        return previousSessionExercise;
      }
    }
    previousSessionExercise = sessionExercise;
  }
  return undefined;
};

export default function Index(state = initialState, action) {
  switch (action.type) {
    case SET_ASSESSMENT_SCREEN: {
      return {
        ...state,
        assessmentScreen: action.payload,
      };
    }
    case SET_INTRO_SCREEN: {
      return {
        ...state,
        introScreen: action.payload,
      };
    }
    case CREATE_PREPARE_PHASE_ANSWER.request: {
      return {
        ...state,
        isAssessing: true,
      };
    }
    case CREATE_PREPARE_PHASE_ANSWER.success: {
      return {
        ...state,
        isAssessing: false,
      };
    }
    case CREATE_PREPARE_PHASE_ANSWER.failure: {
      return {
        ...state,
        isAssessing: false,
      };
    }
    case GET_PREV_EXERCISE:
    case GET_NEXT_EXERCISE: {
      const { sessionExercises } = state;
      const reversed = action.type === GET_PREV_EXERCISE;
      const currentSessionExercise = getCurrentSessionExercise(state);
      let circuitRound = updateCircuitRound(state, reversed);

      let nextSessionExercise = null;
      if (reversed) {
        nextSessionExercise = getPreviousSessionExercise(state, circuitRound);
      } else {
        nextSessionExercise = getNextSessionExercise(state, circuitRound);
      }

      if (!nextSessionExercise) {
        nextSessionExercise = currentSessionExercise;
      }

      if (currentSessionExercise.circuitId !== nextSessionExercise.circuitId) {
        // Reset `circuitRound` when we got a session exercise from
        // yet another circuit.
        if (!reversed) {
          circuitRound = 1;
        }
      }

      return {
        ...state,
        currentSessionExerciseId: nextSessionExercise.id,
        sessionExercises: sessionExercises.map(sessionExercise => {
          let round = {};
          if (nextSessionExercise.circuitId === sessionExercise.id) {
            const circuit = getCircuitById(state)(nextSessionExercise.circuitId);
            const defaultRoundNumber = reversed ? circuit.totalRoundsCount : DEFAULT_ROUND_NUMBER;
            if (circuitRound && circuitRound > circuit.totalRoundsCount) {
              circuitRound = circuit.totalRoundsCount;
            }
            round = {
              round: circuitRound || defaultRoundNumber,
            };
          }
          return {
            ...sessionExercise,
            ...round,
          };
        }),
      };
    }
    case GET_NEXT_EXERCISE_PHASE: {
      const currentPhase = getCurrentExerciseIdPhase(state);
      const currentPhaseSessionExercises = getSessionExercisesInCurrentPhase(state);
      const currentPhaseSessionExercise = getFirstSessionExercise(currentPhaseSessionExercises);

      let sessionExercise = null;
      let nextPhase = currentPhase;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        nextPhase += 1;
        const sessionExercises = getSessionExercisesByPhase(state)(nextPhase);
        sessionExercise = getFirstSessionExercise(sessionExercises);
        if (sessionExercise || nextPhase === 4) {
          break;
        }
      }

      sessionExercise =
        nextPhase <= 4 && sessionExercise ? sessionExercise : currentPhaseSessionExercise;

      return sessionExercise
        ? {
            ...state,
            currentSessionExerciseId: sessionExercise.id,
            exerciseIdPhase: sessionExercise.phase,
          }
        : {
            ...state,
          };
    }
    case FETCH_RESUME_WORKOUT.request: {
      return {
        ...state,
        isResumingSession: true,
      };
    }
    case FETCH_RESUME_WORKOUT.failure: {
      return {
        ...state,
        isResumingSession: false,
      };
    }
    case FETCH_RESUME_WORKOUT.success: {
      const { id: workoutId, phase, round, sessionExercise } = action.payload || {};

      if (sessionExercise) {
        let sessionExercises = getSessionExercises(state);

        let currentSessionExerciseId = sessionExercise.id;
        if (sessionExercise.circuit) {
          currentSessionExerciseId = sessionExercise.circuit.id;
        }

        const currentSessionExerciseIndex = findIndex(sessionExercises, {
          id: currentSessionExerciseId,
          phase,
        });

        sessionExercises = sessionExercises.map((item, idx) => {
          if (idx < currentSessionExerciseIndex) {
            if (item.type === 'circuit') {
              // eslint-disable-next-line no-param-reassign
              item.round = item.totalRoundsCount;
            }
            return {
              ...item,
              completed: true,
            };
          }
          if (idx === currentSessionExerciseIndex) {
            return {
              ...item,
              round,
            };
          }
          return item;
        });

        return {
          ...state,
          exerciseIdPhase: phase,
          currentSessionExerciseId: sessionExercise.id,
          workoutExerciseId: workoutId,
          isResumingSession: false,
          sessionExercises,
        };
      }
      return {
        ...state,
        isResumingSession: false,
      };
    }
    case FETCH_EXERCISE_BY_ID.request: {
      return {
        ...state,
        exerciseDetailsLoading: true,
        exerciseDetails: initialState.exerciseDetails,
      };
    }
    case FETCH_EXERCISE_BY_ID.success: {
      return {
        ...state,
        exerciseDetailsLoading: false,
        exerciseDetails: action.payload,
      };
    }
    case FETCH_SIGN_EXERCISE_VIDEO.success: {
      // this case can never trigger now (action for this case not uses),
      // but if it occur it's crashing application, so i removed it
      // (have issue with getExercisesList and getCurrentExercise)
      return state;
    }
    case FETCH_BASIC_SESSION_EXERCISES_LIST.success: {
      const { timeLength, sessionExercises: receivedSessionExercises } = action.payload;

      // combine basic exercises with prep of current session
      const prepExercises = getSessionExercisesByPhase(state)(1);
      const combinedSessionExercises = prepExercises.concat(receivedSessionExercises);

      // handle circuits
      if (combinedSessionExercises.length) {
        const sessionExercises = combinedSessionExercises.map(sessionExercise => {
          if (sessionExercise.type === 'circuit') {
            /* eslint-disable no-param-reassign */
            sessionExercise.round = sessionExercise.round || DEFAULT_ROUND_NUMBER;
            sessionExercise.totalRoundsCount = sessionExercise.timeMatrix[timeLength];
            sessionExercise.singleRound = sessionExercise.totalRoundsCount === 1;
            sessionExercise.sessionExercises.map(childSessionExercise => {
              childSessionExercise.circuitId = sessionExercise.id;
              return childSessionExercise;
            });
            /* eslint-enable no-param-reassign */
          }
          return sessionExercise;
        });

        const sessionExercise = getFirstSessionExercise(sessionExercises);
        return {
          ...state,
          sessionExercises,
          currentSessionExerciseId: sessionExercise ? sessionExercise.id : null,
          exerciseIdPhase: sessionExercise ? sessionExercise.phase : 1,
        };
      }

      return state;
    }

    case FETCH_SESSION_EXERCISES_LIST.success: {
      const { timeLength, sessionExercises: receivedSessionExercises, sessionId } = action.payload;

      if (!receivedSessionExercises.length || !sessionId) {
        return state;
      }

      const sessionExercises = handleCircuits(
        receivedSessionExercises.map(exercise => ({ ...exercise, sessionId })),
        timeLength,
      );
      const firstSessionExercise = getFirstSessionExercise(sessionExercises);

      return {
        ...state,
        sessionExercises,
        currentSessionExerciseId: firstSessionExercise?.id ?? null,
        exerciseIdPhase: firstSessionExercise?.phase ?? 1,
      };
    }

    case FETCH_SESSION_EXERCISES_LIST.failure: {
      return {
        ...state,
      };
    }

    case COMPLETE_EXERCISE.success: {
      const { sessionExercises } = state;
      const circuitRound = getCurrentCircuitRound(state);
      const iterator = makeSessionExercisesIterator(sessionExercises);
      const currentSessionExercise = getCurrentSessionExercise(state);
      let completedSessionExerciseId = null;
      let completedChildSessionExerciseId = null;
      // eslint-disable-next-line no-restricted-syntax
      for (const sessionExercise of iterator) {
        if (sessionExercise.id === currentSessionExercise.id) {
          if (sessionExercise.circuitId) {
            if (sessionExercise.totalRoundsCount === circuitRound) {
              completedChildSessionExerciseId = sessionExercise.id;
              if (sessionExercise.lastInCircuit) {
                // This is the last session exercise in the circuit.
                completedSessionExerciseId = sessionExercise.circuitId;
              }
            }
          } else {
            completedSessionExerciseId = sessionExercise.id;
            break;
          }
        }
      }
      return {
        ...state,
        sessionExercises: sessionExercises.map(sessionExercise => {
          if (sessionExercise.type === 'circuit') {
            const newChildExercises = sessionExercise.sessionExercises.map(childSessionExercise => {
              const childCompleted =
                childSessionExercise.id === completedChildSessionExerciseId
                  ? {
                      completed: true,
                      status: SESSION_EXERCISE_STATUS.COMPLETED,
                    }
                  : {};
              return {
                ...childSessionExercise,
                ...childCompleted,
              };
            });

            const completed =
              sessionExercise.id === completedSessionExerciseId
                ? {
                    completed: true,
                    status: SESSION_EXERCISE_STATUS.COMPLETED,
                  }
                : {};

            // eslint-disable-next-line no-param-reassign
            sessionExercise.sessionExercises = newChildExercises;
            return {
              ...sessionExercise,
              ...completed,
            };
          }
          const completed =
            sessionExercise.id === completedSessionExerciseId
              ? {
                  completed: true,
                  status: SESSION_EXERCISE_STATUS.COMPLETED,
                }
              : {};
          return {
            ...sessionExercise,
            ...completed,
          };
        }),
      };
    }

    case CLEAR_EXERCISE_DETAILS: {
      return {
        ...state,
        exerciseDetails: null,
      };
    }

    case CLEAR_EXERCISES_STATE: {
      return {
        all: {},
        exerciseId: null,
        currentSessionExerciseId: null,
        workoutExerciseId: null,
        exerciseDetails: null,
        isResumingSession: false,
        exerciseIdPhase: null,
        isAssessing: false,
        isAddingCard: false,
        assessmentScreen: false,
        introScreen: true,
        sessionExercises: [],
      };
    }

    default:
      return state;
  }
}

const getFirstSessionExercise = sessionExercises => {
  const iterator = makeSessionExercisesIterator(sessionExercises);
  // eslint-disable-next-line no-restricted-syntax
  for (const sessionExercise of iterator) {
    return sessionExercise;
  }
  return undefined;
};

function handleCircuits(exercises, timeLength) {
  return exercises.map(exercise => {
    if (exercise.type !== 'circuit') return exercise;

    return {
      ...exercise,
      round: exercise.round || DEFAULT_ROUND_NUMBER,
      totalRoundsCount: exercise.timeMatrix[timeLength],
      singleRound: exercise.timeMatrix[timeLength] === 1,
      sessionExercises: exercise.sessionExercises.map(childExercise => ({
        ...childExercise,
        circuitId: exercise.id,
      })),
    };
  });
}
