import { Program } from 'reducers/programs/types';

export enum PROGRAMS_ACTION_TYPES {
  LOADING = 'LOADING_PROGRAMS',
  LOADED = 'LOADED_PROGRAMS',
  FAILURE = 'FAILED_PROGRAMS',
}

export type ProgramsActionPayload = {
  id: number;
  error?: string;
};

export type FetchProgramsPayloadType = {
  forceUpdate?: boolean;
  error: string | null;
};

export const fetchPrograms = (payload?: FetchProgramsPayloadType) => ({
  type: PROGRAMS_ACTION_TYPES.LOADING,
  payload,
});

export const fetchProgramsSuccess = (payload: { error: string | null }) => ({
  type: PROGRAMS_ACTION_TYPES.LOADED,
  payload,
});

export const fetchProgramsFailed = (payload: { error: string | null }) => ({
  type: PROGRAMS_ACTION_TYPES.FAILURE,
  payload,
});

export enum SCHEDULE_ACTION_TYPES {
  UPDATING = 'UPDATING_SCHEDULE',
  UPDATED = 'UPDATED_SCHEDULE',
  FAILURE = 'FAILED_SCHEDULE',
}

export type ScheduleActionPayload = {
  programs: Program[];
};

export const updateSchedule = (payload: { error: string | null }) => ({
  type: SCHEDULE_ACTION_TYPES.UPDATING,
  payload,
});

export const updateScheduleSuccess = (payload: { error: string | null }) => ({
  type: SCHEDULE_ACTION_TYPES.UPDATED,
  payload,
});

export const updateScheduleFailed = () => ({
  type: SCHEDULE_ACTION_TYPES.FAILURE,
});

export enum PROGRAM_SCHEDULE_ACTION_TYPES {
  UPDATING = 'UPDATING_PROGRAM_SCHEDULE',
  UPDATED = 'UPDATED_PROGRAM_SCHEDULE',
  FAILURE = 'FAILED_PROGRAM_SCHEDULE',
}

export type ProgramScheduleActionPayload = {
  programId: number;
  schedule: {
    [key: string]: boolean;
  };
};

export const updateProgramSchedule = (payload: ProgramScheduleActionPayload) => ({
  type: PROGRAM_SCHEDULE_ACTION_TYPES.UPDATING,
  payload,
});

export const updateProgramScheduleSuccess = (payload: { error: string | null }) => ({
  type: PROGRAM_SCHEDULE_ACTION_TYPES.UPDATED,
  payload,
});

export const updateProgramScheduleFailed = () => ({
  type: PROGRAM_SCHEDULE_ACTION_TYPES.FAILURE,
});
