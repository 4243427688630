import { put, call, all } from 'redux-saga/effects';
import api from '../utils/api';
import { receiveFetchBlogPosts, failureFetchBlogPosts } from '../actions/community';

export default function* fetchBlogPosts(action) {
  try {
    const { numberOfPosts } = action.payload;

    const { data } = yield call(
      api().get,
      `https://gmb.io/wp-json/wp/v2/posts/?per_page=${numberOfPosts}&post_type=posts`,
    );

    // eslint-disable-next-line no-underscore-dangle
    const postImagesURLs = data.map(post => `${post._links['wp:featuredmedia'][0].href}/`);
    const postsImagesRequests = postImagesURLs.map(post => api().get(post));

    const postsImages = (yield all(postsImagesRequests)).map(response => response.data);

    yield put(receiveFetchBlogPosts({ data, postsImages }));
  } catch (error) {
    yield put(failureFetchBlogPosts('Something went wrong'));
  }
}
