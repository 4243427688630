import { select, call, put } from 'redux-saga/effects';

import { getUser } from 'reducers/user';
import api from 'utils/api';
import {
  FetchProgramsPayloadType,
  fetchProgramsSuccess,
  ProgramScheduleActionPayload,
  ScheduleActionPayload,
  updateProgramScheduleSuccess,
  updateScheduleSuccess,
} from 'actions/programs';
import { Action, StoreType } from 'reducers/types';
import { fetchErrorHandler } from 'actions/fetchErrorHandler';
import { requestFetchActivities } from 'actions/activities';

const URL = '/users';

export function* fetchPrograms({
  payload,
}: Action<FetchProgramsPayloadType>): Generator<any, void, any> {
  const forceUpdate = payload?.forceUpdate ?? false;
  try {
    const { cookies } = yield select(getUser);
    const programs = yield select((state: StoreType) => state.programs.all);

    // check if programs are already fetched
    if (programs.length > 0 && !forceUpdate) {
      return;
    }

    const { data } = yield call(api(cookies).get, `${URL}/programs`);
    yield put(fetchProgramsSuccess({ ...data }));
  } catch (error) {
    // @ts-ignore
    yield put(fetchErrorHandler(error.response, 'Error while fetching programs'));
  }
}

export function* updateProgramsSchedule({ payload }: Action<ScheduleActionPayload>) {
  const { programs } = payload;
  try {
    const { id, cookies } = yield select(getUser);

    const { data } = yield call(api(cookies).put, `${URL}/${id}/programs/schedule`, {
      programList: programs,
    });

    const userPrograms = {
      userPrograms: data,
      error: null,
    };

    yield put(updateScheduleSuccess({ ...userPrograms }));
  } catch (error) {
    // @ts-ignore
    yield put(fetchErrorHandler(error.response, 'Error while updating user schedule'));
  }
}

export function* updateProgramSchedule({ payload }: Action<ProgramScheduleActionPayload>) {
  const { schedule, programId } = payload;

  try {
    const { id, cookies } = yield select(getUser);

    const { data } = yield call(api(cookies).put, `${URL}/${id}/programs/${programId}`, {
      schedule,
    });
    const userPrograms = {
      userPrograms: [data],
      error: null,
    };

    yield put(updateProgramScheduleSuccess(userPrograms));
    yield put(requestFetchActivities({}));
  } catch (error) {
    yield put(fetchErrorHandler(error, 'Error while updating program schedule'));
  }
}
