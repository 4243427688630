import { FC } from 'react';
import { TextProps } from './types';
import useTagName from './hooks/useTagName';

const Tag: FC<TextProps> = ({ variant, tag, className, children }) => {
  const DynamicTag = useTagName(variant, tag);

  return <DynamicTag className={className}>{children}</DynamicTag>;
};

export default Tag;
