import toast from 'react-hot-toast';
import { SHOW_TOAST } from '../../actions/toasts';

const Index = (state = {}, action) => {
  switch (action.type) {
    case SHOW_TOAST.default: {
      const { options, message } = action;

      if (options?.appearance === 'error' || options?.appearance === 'success') {
        toast[options.appearance](message, {
          id: message,
          reload: options.reload,
        });
        return state;
      }

      toast(message, {
        id: message,
      });
      return state;
    }
    case SHOW_TOAST.fetchError: {
      toast.error(action.message, {
        id: action.message,
      });

      return state;
    }
    default:
      return state;
  }
};

export default Index;
