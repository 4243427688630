// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { Integrations } from '@sentry/tracing';
import getConfig from 'next/config';
import packageInfo from './package.json';

// This file runs on client and there is no process.env
const { sentry } = getConfig().publicRuntimeConfig;

if (sentry.dsn) {
  Sentry.init({
    dsn: sentry.dsn,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['localhost', 'app.gmb.io', /^\//],
      }),
    ],
    environment: sentry.environment,
    debug: sentry.debug,
    allowUrls: [/app\.gmb\.io/, /gmb\-fitness/],
    beforeSend(event) {
      try {
        const eventValue = event.exception.values[0].value;
        if (
          JSON.stringify(eventValue) === '{"isTrusted":false}' ||
          eventValue.includes('Non-Error promise rejection') ||
          eventValue.includes('ResizeObserver') ||
          eventValue.includes('AbortError') ||
          eventValue.includes('Request aborted') ||
          eventValue.includes('The request is not allowed') ||
          eventValue.includes('Non-Error exception') ||
          eventValue.includes('NotSupportedError') ||
          eventValue.includes('instantSearchSDKJSBridgeClearHighlight') ||
          eventValue.includes('SecurityError: The operation is insecure') ||
          eventValue.includes('QuotaExceededError') ||
          eventValue.includes('NotAllowedError') ||
          eventValue.includes('InvalidStateError: The object is in an invalid state')
        ) {
          return null;
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}

      return event;
    },
    tracesSampleRate: 0.3,
    beforeBreadcrumb(breadcrumb) {
      const { category, data } = breadcrumb;

      // prevent logging successful requests to mux videos
      if (category === 'xhr' && data.status_code === 200 && data.url.includes('litix.io')) {
        return null;
      }

      return breadcrumb;
    },
    ignoreErrors: [
      // Google Tag manager
      /^Cannot redefine property: googletag$/,
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
    ],
    ignoreUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });

  Sentry.setTag('part', 'pwa-client');
  Sentry.setTag('version', packageInfo.version);
}
