import { DEFAULT_OPTIONS } from '../constants';

export const SHOW_TOAST = {
  default: 'SHOW_TOAST_DEFAULT',
  fetchError: 'SHOW_TOAST_FETCH_ERROR',
};

export const showToastDefault = (message, options = DEFAULT_OPTIONS) => ({
  type: SHOW_TOAST.default,
  message,
  options,
});

export const showToastFetchError = (message, data) => ({
  type: SHOW_TOAST.fetchError,
  message,
  data,
});
