import challenging from '../assets/icons/pictograms/ic_ponder_challenging.svg';
import relaxed from '../assets/icons/pictograms/ic_ponder_relaxed.svg';
import solid from '../assets/icons/pictograms/ic_ponder_solid.svg';
import maximumEffort from '../assets/icons/pictograms/ic_ponder_maximum_effort.svg';

import snappy from '../assets/icons/pictograms/ic_ponder_snappy.svg';
import rough from '../assets/icons/pictograms/ic_ponder_rough.svg';
import smooth from '../assets/icons/pictograms/ic_ponder_smooth.svg';
import broken from '../assets/icons/pictograms/ic_ponder_broken.svg';

export const pictogramsEnum = {
  1: 'relaxed',
  2: 'solid',
  3: 'challenging',
  4: 'maximum_effort',

  5: 'snappy',
  6: 'smooth',
  7: 'rough',
  8: 'broken',
};

export const pictograms = {
  [pictogramsEnum[1]]: relaxed,
  [pictogramsEnum[2]]: solid,
  [pictogramsEnum[3]]: challenging,
  [pictogramsEnum[4]]: maximumEffort,

  [pictogramsEnum[5]]: snappy,
  [pictogramsEnum[6]]: smooth,
  [pictogramsEnum[7]]: rough,
  [pictogramsEnum[8]]: broken,
};

export const PONDER_EASE = {
  4: 'Relaxed',
  3: 'Solid',
  2: 'Challenging',
  1: 'Maximum Effort',
};

export const PONDER_QUALITY = {
  4: 'Snappy',
  3: 'Smooth',
  2: 'Rough',
  1: 'Broke',
};

export const EASE = [
  {
    name: PONDER_EASE[4],
    description:
      "It's starting to feel less like work and more like a normal way to move and play. You can laugh while you move.",
    icon: pictogramsEnum[1],
    value: 4,
  },
  {
    name: PONDER_EASE[3],
    description:
      'Your movement feels strong and steady with an enjoyable sense of effort. You can keep this up for a while.',
    icon: pictogramsEnum[2],
    value: 3,
  },
  {
    name: PONDER_EASE[2],
    description:
      "The work is difficult and fatigue is building up, but it's not killing you. If you force more reps, things might get bad.",
    icon: pictogramsEnum[3],
    value: 2,
  },
  {
    name: PONDER_EASE[1],
    description:
      "You're basically trying to survive. Form isn't even on your mind. Not recommended as a regular state.",
    icon: pictogramsEnum[4],
    value: 1,
  },
];

export const QUALITY = [
  {
    name: PONDER_QUALITY[4],
    description:
      'You got this. You can control your movements at any speed. Your body snaps into place like a well-oiled machine.',
    icon: pictogramsEnum[5],
    value: 4,
  },
  {
    name: PONDER_QUALITY[3],
    description:
      'You feel in control, in a groove with the movements, and can work hard at them without breaking form.',
    icon: pictogramsEnum[6],
    value: 3,
  },
  {
    name: PONDER_QUALITY[2],
    description:
      "You aren't hurting yourself, but you should probably do fewer reps and focus on slowing down and smoothing it out.",
    icon: pictogramsEnum[7],
    value: 2,
  },
  {
    name: PONDER_QUALITY[1],
    description:
      'This was too much for you. Reduce the difficulty level and work your way up to these movements.',
    icon: pictogramsEnum[8],
    value: 1,
  },
];
