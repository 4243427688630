import { put, call, select } from 'redux-saga/effects';
import Router from 'next/router';
import * as Sentry from '@sentry/nextjs';
import api from '../utils/api';
import { getUserCookies } from '../reducers/user';
import {
  receiveFetchProgramSessions,
  failureFetchProgramSessions,
  receiveFetchProgramSessionById,
  failureFetchProgramSessionById,
  successFetchExpandedSessionInfo,
  failureFetchExpandedSessionInfo,
} from '../actions/sessions';
import { fetchErrorHandler } from '../actions/fetchErrorHandler';

// fetch all programs
export function* fetchProgramSessions() {
  try {
    const accessToken = yield select(getUserCookies);

    const { data } = yield call(api(accessToken).get, `/sessions`);

    yield put(receiveFetchProgramSessions(data));
  } catch (error) {
    yield put(fetchErrorHandler(error.response, 'Error while fetching sessions'));
    yield put(failureFetchProgramSessions('Something went wrong'));
  }
}

// fetch program workout session
export function* fetchProgramSessionById(action) {
  try {
    const { sessionId } = action.payload;

    if (!sessionId) {
      Sentry.captureMessage('Session is not defined', {
        level: Sentry.Severity.Error,
        extra: { action },
      });
      throw new Error('Session is not defined');
    }

    const { data } = yield call(api().get, `/sessions/${sessionId}`);

    yield put(receiveFetchProgramSessionById(data));
  } catch (error) {
    yield put(fetchErrorHandler(error.response, 'Error while fetching session'));
    yield put(failureFetchProgramSessionById('Something went wrong'));
  }
}

export function* fetchExpandedSessionInfo(action) {
  try {
    const { sessionId } = action.payload;

    const accessToken = yield select(getUserCookies);
    const { data } = yield call(api(accessToken).get, `/sessions/${sessionId}/expanded`);

    yield put(successFetchExpandedSessionInfo(data));
  } catch (error) {
    yield put(fetchErrorHandler(error.response || error, 'Something went wrong'));
    yield put(failureFetchExpandedSessionInfo());
    Router.push('/');
  }
}
