import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

export const appInfo = {
    // learn more about this on https://supertokens.com/docs/thirdpartyemailpassword/appinfo
    appName: "Praxis",
    apiDomain: publicRuntimeConfig.apiUrl,
    websiteDomain: publicRuntimeConfig.baseUrl,
    apiBasePath: "api/auth",
    websiteBasePath: "/auth"
  }
  