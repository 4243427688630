import * as React from 'react';
import { ToastBar, Toaster, toast } from 'react-hot-toast';
import Text from 'components/core/Text';
import { ToastContent, StyledButton, ToastDefaultStyles } from './styles';

function pageReload() {
  window.location.reload();
}

const Toast = () => {
  return (
    <Toaster
      position="bottom-center"
      reverseOrder={false}
      gutter={8}
      containerStyle={{}}
      toastOptions={{
        // Define default options
        duration: 1000000,
        style: ToastDefaultStyles,
        // Default options for specific types
        success: {
          icon: '✅',
        },
        error: {
          icon: '❌',
        },
      }}
    >
      {t => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <ToastContent>
              {icon}
              <Text variant="textSmall" tag="div" weight="medium">
                {message}
              </Text>
              {t.type !== 'loading' && (
                <div>
                  {t.reload && (
                    <StyledButton reload handleClick={pageReload}>
                      Reload
                    </StyledButton>
                  )}
                  <StyledButton hidden={t.reload} handleClick={() => toast.dismiss(t.id)}>
                    Dismiss
                  </StyledButton>
                </div>
              )}
            </ToastContent>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
};

export default Toast;
