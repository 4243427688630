import { DAYS_OF_WEEK } from '../constants';

const sortDaysOfWeek = schedule => {
  return schedule
    ? Object.keys(schedule)
        .sort((day1, day2) => {
          return DAYS_OF_WEEK[day1.toLowerCase()] - DAYS_OF_WEEK[day2.toLocaleLowerCase()];
        })
        .reduce((acc, day) => {
          acc[day] = schedule[day];

          return acc;
        }, {})
    : {};
};

export default sortDaysOfWeek;
