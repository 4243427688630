// FETCH QUESTIONNAIRE
export const FETCH_QUESTIONNAIRE = {
  request: 'FETCH_QUESTIONNAIRE_REQUEST',
  success: 'FETCH_QUESTIONNAIRE_SUCCESS',
  failure: 'FETCH_QUESTIONNAIRE_FAILURE',
};

export const requestFetchQuestionnaire = payload => ({
  type: FETCH_QUESTIONNAIRE.request,
  payload,
});

export const receiveFetchQuestionnaire = payload => ({
  type: FETCH_QUESTIONNAIRE.success,
  payload,
});

export const failureFetchQuestionnaire = payload => ({
  type: FETCH_QUESTIONNAIRE.failure,
  payload,
});

// SELECT QUESTIONNAIRE ANSWER
export const SELECT_QUESTIONNAIRE_ANSWER = 'SELECT_QUESTIONNAIRE_ANSWER';

export const selectQuestionnaireAnswer = payload => ({
  type: SELECT_QUESTIONNAIRE_ANSWER,
  payload,
});

// SELECT QUESTIONNAIRE SUB ANSWER
export const SELECT_QUESTIONNAIRE_SUBANSWER = 'SELECT_QUESTIONNAIRE_SUBANSWER';

export const selectQuestionnaireSubAnswer = payload => ({
  type: SELECT_QUESTIONNAIRE_SUBANSWER,
  payload,
});

// SELECT NEXT QUESTIONNAIRE QUESTION
export const SELECT_QUESTIONNAIRE_NEXT_QUESTION = 'SELECT_QUESTIONNAIRE_NEXT_QUESTION';

export const selectQuestionnaireNextQuestion = payload => ({
  type: SELECT_QUESTIONNAIRE_NEXT_QUESTION,
  payload,
});

// SELECT PREV QUESTIONNAIRE QUESTION
export const SELECT_QUESTIONNAIRE_PREV_QUESTION = 'SELECT_QUESTIONNAIRE_PREV_QUESTION';

export const selectQuestionnairePrevQuestion = payload => ({
  type: SELECT_QUESTIONNAIRE_PREV_QUESTION,
  payload,
});

// CHANGE OTHER VALUE AND SUBVALUE
export const CHANGE_OTHER_VALUE = 'CHANGE_OTHER_VALUE';
export const CHANGE_OTHER_SUBVALUE = 'CHANGE_OTHER_SUBVALUE';

export const changeOtherValue = payload => ({
  type: CHANGE_OTHER_VALUE,
  payload,
});

export const changeOtherSubValue = payload => ({
  type: CHANGE_OTHER_SUBVALUE,
  payload,
});

// SEND QUESTIONNAIRE ANSWERS
export const SEND_QUESTIONNAIRE_ANSWERS = {
  request: 'SEND_QUESTIONNAIRE_ANSWERS_REQUEST',
  success: 'SEND_QUESTIONNAIRE_ANSWERS_SUCCESS',
  failure: 'SEND_QUESTIONNAIRE_ANSWERS_FAILURE',
};

export const requestSendQuestionnaireAnswers = payload => ({
  type: SEND_QUESTIONNAIRE_ANSWERS.request,
  payload,
});

export const receiveSendQuestionnaireAnswers = payload => ({
  type: SEND_QUESTIONNAIRE_ANSWERS.success,
  payload,
});

export const failureSenedQuestionnaireAnswers = payload => ({
  type: SEND_QUESTIONNAIRE_ANSWERS.failure,
  payload,
});

// CHANGE RUNNING STATE FOR QUESTIONNAIRE
export const CHANGE_RUNNING_QUESTIONNAIRE_STATE = 'CHANGE_RUNNING_QUESTIONNAIRE_STATE';

export const changeRunningQuestionnaireState = payload => ({
  type: CHANGE_RUNNING_QUESTIONNAIRE_STATE,
  payload,
});

// GET USER QUESTIONNAIRE ANSWERS
export const FETCH_QUESTIONNAIRE_ANSWERS = {
  request: 'FETCH_QUESTIONNAIRE_ANSWERS_REQUEST',
  success: 'FETCH_QUESTIONNAIRE_ANSWERS_SUCCESS',
  failure: 'FETCH_QUESTIONNAIRE_ANSWERS_FAILURE',
};

export const requestFetchQuestionnaireAnswers = payload => ({
  type: FETCH_QUESTIONNAIRE_ANSWERS.request,
  payload,
});

export const receiveFetchQuestionnaireAnswers = payload => ({
  type: FETCH_QUESTIONNAIRE_ANSWERS.success,
  payload,
});

export const failureFetchQuestionnaireAnswers = payload => ({
  type: FETCH_QUESTIONNAIRE_ANSWERS.failure,
  payload,
});
