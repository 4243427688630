import * as React from 'react';

import Spinner from '../Spinner';

import Wrapper from './styles';

// Main project button
export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pressed: false,
    };
  }

  handleMouseDown = () => {
    const { pressed } = this.state;
    this.setState({ pressed: !pressed });
  };

  render() {
    const {
      type = 'button',
      margin = '',
      disabled = false,
      reduced = false,
      secondary = false,
      isLoading = false,
      handleClick,
      className,
      children,
    } = this.props;
    const { pressed } = this.state;

    return (
      <Wrapper
        className={className}
        type={type}
        disabled={disabled || isLoading}
        reduced={reduced}
        margin={margin}
        onClick={handleClick}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseDown}
        onMouseLeave={() => pressed && this.handleMouseDown()}
        onTouchStart={this.handleMouseDown}
        onTouchEnd={this.handleMouseDown}
        pressed={pressed}
        secondary={secondary}
      >
        {!isLoading ? children : <Spinner />}
      </Wrapper>
    );
  }
}
