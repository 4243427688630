// FETCH USER SESSIONS (ACTIVITIES)
export const FETCH_WORKOUT_SESSIONS = {
  request: 'FETCH_WORKOUT_SESSIONS_REQUEST',
  success: 'FETCH_WORKOUT_SESSIONS_SUCCESS',
  failure: 'FETCH_WORKOUT_SESSIONS_FAILURE',
};

export const requestFetchWorkoutSessions = payload => ({
  type: FETCH_WORKOUT_SESSIONS.request,
  payload,
});

export const receiveFetchWorkoutSessions = payload => ({
  type: FETCH_WORKOUT_SESSIONS.success,
  payload,
});

export const errorFetchWorkoutSessions = payload => ({
  type: FETCH_WORKOUT_SESSIONS.failure,
  payload,
});

// user workout by program
export const FETCH_WORKOUT_SESSIONS_PROGRAM = {
  request: 'FETCH_WORKOUT_SESSIONS_PROGRAM_REQUEST',
  success: 'FETCH_WORKOUT_SESSIONS_PROGRAM_SUCCESS',
  failure: 'FETCH_WORKOUT_SESSIONS_PROGRAM_FAILURE',
};

export const requestFetchWorkoutSessionsProgram = payload => ({
  type: FETCH_WORKOUT_SESSIONS_PROGRAM.request,
  payload,
});

export const receiveFetchWorkoutSessionsProgram = payload => ({
  type: FETCH_WORKOUT_SESSIONS_PROGRAM.success,
  payload,
});

export const failureFetchWorkoutSessionsProgram = payload => ({
  type: FETCH_WORKOUT_SESSIONS_PROGRAM.failure,
  payload,
});

// fetch user workout by session id
export const FETCH_WORKOUT_SESSION_BY_ID = {
  request: 'FETCH_WORKOUT_SESSION_BY_ID_REQUEST',
  success: 'FETCH_WORKOUT_SESSION_BY_ID_SUCCESS',
  failure: 'FETCH_WORKOUT_SESSION_BY_ID_FAILURE',
};

export const requestFetchUserWorkoutSessionById = payload => ({
  type: FETCH_WORKOUT_SESSION_BY_ID.request,
  payload,
});

export const receiveFetchUserWorkoutSessionById = payload => ({
  type: FETCH_WORKOUT_SESSION_BY_ID.success,
  payload,
});

export const failureFetchUserWorkoutSessionById = payload => ({
  type: FETCH_WORKOUT_SESSION_BY_ID.failure,
  payload,
});

// create user workout
export const CREATE_WORKOUT = {
  idle: 'CREATE_WORKOUT_IDLE', // creation not started yet
  request: 'CREATE_WORKOUT_REQUEST',
  success: 'CREATE_WORKOUT_SUCCESS',
  failure: 'CREATE_WORKOUT_FAILURE',
};

export const idleCreateUserWorkout = () => ({
  type: CREATE_WORKOUT.idle,
});

export const requestCreateUserWorkout = payload => ({
  type: CREATE_WORKOUT.request,
  payload,
});

export const receiveCreateUserWorkout = payload => ({
  type: CREATE_WORKOUT.success,
  payload,
});

export const failureCreateUserWorkout = payload => ({
  type: CREATE_WORKOUT.failure,
  payload,
});

// delete user workout
export const DELETE_WORKOUT = {
  request: 'DELETE_WORKOUT_REQUEST',
  success: 'DELETE_WORKOUT_SUCCESS',
  failure: 'DELETE_WORKOUT_FAILURE',
};

export const requestDeleteUserWorkout = () => ({
  type: DELETE_WORKOUT.request,
});

export const receiveDeleteUserWorkout = payload => ({
  type: DELETE_WORKOUT.success,
  payload,
});

export const failureDeleteUserWorkout = payload => ({
  type: DELETE_WORKOUT.failure,
  payload,
});

// prepare phase answer
export const PREPARE_PHASE_ANSWER = {
  request: 'PREPARE_PHASE_ANSWER_REQUEST',
  success: 'PREPARE_PHASE_ANSWER_SUCCESS',
  failure: 'PREPARE_PHASE_ANSWER_FAILURE',
};

export const requestPreparePhaseAnswer = payload => ({
  type: PREPARE_PHASE_ANSWER.request,
  payload,
});

export const receivePreparePhaseAnswer = payload => ({
  type: PREPARE_PHASE_ANSWER.success,
  payload,
});

export const failurePreparePhaseAnswer = payload => ({
  type: PREPARE_PHASE_ANSWER.failure,
  payload,
});

// done workout
export const DONE_WORKOUT = {
  idle: 'DONE_WORKOUT_IDLE',
  request: 'DONE_WORKOUT_REQUEST',
  success: 'DONE_WORKOUT_SUCCESS',
  failure: 'DONE_WORKOUT_FAILURE',
};

export const idleDoneWorkout = () => ({
  type: DONE_WORKOUT.idle,
});

export const requestDoneWorkout = payload => ({
  type: DONE_WORKOUT.request,
  payload,
});

export const receiveDoneWorkout = payload => ({
  type: DONE_WORKOUT.success,
  payload,
});

export const failureDoneWorkout = payload => ({
  type: DONE_WORKOUT.failure,
  payload,
});

// do prepare phase answer
export const CREATE_PREPARE_PHASE_ANSWER = {
  request: 'CREATE_PREPARE_PHASE_ANSWER_REQUEST',
  success: 'CREATE_PREPARE_PHASE_ANSWER_SUCCESS',
  failure: 'CREATE_PREPARE_PHASE_ANSWER_FAILURE',
};

export const requestCreatePreparePhaseAnswer = payload => ({
  type: CREATE_PREPARE_PHASE_ANSWER.request,
  payload,
});

export const receiveCreatePreparePhaseAnswer = payload => ({
  type: CREATE_PREPARE_PHASE_ANSWER.success,
  payload,
});

export const failureCreatePreparePhaseAnswer = payload => ({
  type: CREATE_PREPARE_PHASE_ANSWER.failure,
  payload,
});

// START EXERCISE
export const START_EXERCISE = {
  request: 'START_EXERCISE_REQUEST',
  success: 'START_EXERCISE_SUCCESS',
};

export const requestStartExercise = payload => ({
  type: START_EXERCISE.request,
  payload,
});

export const recieveStartExercise = payload => ({
  type: START_EXERCISE.success,
  payload,
});

// fetch Resume Workout
export const FETCH_RESUME_WORKOUT = {
  request: 'FETCH_RESUME_WORKOUT_REQUEST',
  success: 'FETCH_RESUME_WORKOUT_SUCCESSS',
  failure: 'FETCH_RESUME_WORKOUT_FAILURE',
};

export const requestFetchResumeWorkout = payload => ({
  type: FETCH_RESUME_WORKOUT.request,
  payload,
});

export const receiveFetchResumeWorkout = payload => ({
  type: FETCH_RESUME_WORKOUT.success,
  payload,
});

export const failureFetchResumeWorkout = payload => ({
  type: FETCH_RESUME_WORKOUT.failure,
  payload,
});

// fetch workout by id
export const FETCH_WORKOUT_BY_ID = {
  request: 'FETCH_WORKOUT_BY_ID_REQUEST',
  success: 'FETCH_WORKOUT_BY_ID_SUCCESS',
  failure: 'FETCH_WORKOUT_BY_ID_FAILURE',
};

export const requestFetchWorkoutById = payload => ({
  type: FETCH_WORKOUT_BY_ID.request,
  payload,
});

export const receiveFetchWorkoutById = payload => ({
  type: FETCH_WORKOUT_BY_ID.success,
  payload,
});

export const failureFetchWorkoutById = payload => ({
  type: FETCH_WORKOUT_BY_ID.failure,
  payload,
});

export const FETCH_WORKOUT_SESSION = {
  request: 'FETCH_WORKOUT_SESSION_REQUEST',
  success: 'FETCH_WORKOUT_SESSION_SUCCESS',
  failure: 'FETCH_WORKOUT_SESSION_FAILURE',
};

export const requestFetchWorkoutSession = payload => ({
  type: FETCH_WORKOUT_SESSION.request,
  payload,
});

export const receiveFetchWorkoutSession = payload => ({
  type: FETCH_WORKOUT_SESSION.success,
  payload,
});

export const failureFetchWorkoutSession = payload => ({
  type: FETCH_WORKOUT_SESSION.failure,
  payload,
});

export const FETCH_WORKOUT_EXERCISES = {
  success: 'FETCH_WORKOUT_EXERCISES_SUCCESS',
};

export const receiveFetchWorkoutExercises = payload => ({
  type: FETCH_WORKOUT_EXERCISES.success,
  payload,
});

// filter workouts
export const SET_FILTER = {
  request: 'SET_FILTER_REQUEST',
  success: 'SET_FILTER_SUCCESS',
  fail: 'SET_FILTER_FAIL',
};

export const setFilter = payload => ({
  type: SET_FILTER.success,
  payload,
});

// update workout survey
export const UPDATE_WORKOUT_SURVEY = {
  request: 'UPDATE_WORKOUT_SURVEY_REQUEST',
  success: 'UPDATE_WORKOUT_SURVEY_SUCCESS',
  failure: 'UPDATE_WORKOUT_SURVEY_FAILURE',
};

export const requestUpdateWorkoutSurvey = payload => ({
  type: UPDATE_WORKOUT_SURVEY.request,
  payload,
});

export const receiveUpdateWorkoutSurvey = payload => ({
  type: UPDATE_WORKOUT_SURVEY.success,
  payload,
});

export const failureUpdateWorkoutSurvey = payload => ({
  type: UPDATE_WORKOUT_SURVEY.failure,
  payload,
});
