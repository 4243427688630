import React from 'react';

import { Spinner, SpinnerElement } from './styles';

export default function Index() {
  return (
    <Spinner>
      <SpinnerElement />
      <SpinnerElement />
      <SpinnerElement />
    </Spinner>
  );
}
