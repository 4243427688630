export const FETCH_ACHIEVEMENTS = {
  request: 'FETCH_ACHIEVEMENTS_REQUEST',
  success: 'FETCH_ACHIEVEMENTS_SUCCESS',
  failure: 'FETCH_ACHIEVEMENTS_FAILURE',
};

export const requestFetchAchievements = payload => ({
  type: FETCH_ACHIEVEMENTS.request,
  payload,
});

export const receiveFetchAchievements = payload => ({
  type: FETCH_ACHIEVEMENTS.success,
  payload,
});

export const failureFetchAchievements = payload => ({
  type: FETCH_ACHIEVEMENTS.failure,
  payload,
});
