import styled from 'styled-components';
import colors from 'constants/colors';
import { space } from 'styled-system';
import theme from 'constants/theme';
import getAlign from './utils';
import Tag from './Tag';
import { Variant, Color, Weight } from './types';

const styles: Record<Variant, string> = {
  extraPromoHeader: `
    font-size: 88px;
    font-weight: 300;
    text-transform: uppercase;
  `,
  promoHeader: `
    font-size: 64px;
    font-weight: 300;
    text-transform: uppercase;
  `,
  mainHeader: `
    font-size: 48px;
    font-weight: 300;
    line-height: 50px;
    text-transform: uppercase;
  `,
  displayX: `
    font-size: 36px;
    font-weight: 700;
  `,
  displayM: `
    font-size: 24px;
    font-weight: 700;
  `,
  display: `
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.08em;
    text-transform: uppercase;
  `,
  sectionHeader: `
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.06em;
    line-height: 22px;
  `,
  text: `
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.025em;
  `,
  textSmall: `
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.02em;
    line-height: 18px;
  `,
  caption: `
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.1em;
  `,
  captionSmall: `
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.1em;
    line-height: 12px;
    text-transform: uppercase;
  `,
};

const mobileStyles: Record<Variant, string> = {
  extraPromoHeader: ``,
  promoHeader: `
    font-size: 48px;
  `,
  mainHeader: `
    font-size: 32px;
    line-height: 32px;
  `,
  displayX: ``,
  displayM: `
    font-size: 16px;
  `,
  display: ``,
  sectionHeader: ``,
  text: ``,
  textSmall: ``,
  caption: ``,
  captionSmall: ``,
};

const colorsConfig: Record<Color, string> = {
  currentColor: 'currentColor',
  black: `${colors.main.black}`,
  white: `${colors.main.white}`,
  gray: `${colors.main.grey}`,
  grayLight: `${colors.main.placeholdersAndDisabledBtnsText}`,
  grayExtraLight: `${colors.main.disabledBtns}`,
  blue: `${colors.main.blue}`,
  blueGray: `${colors.main.blueGray}`,
  ruby: `${colors.main.ruby}`,
};

const weightConfig: Record<Weight, number> = {
  light: 300,
  normal: 400,
  medium: 500,
  bold: 700,
};

const StyledTypography = styled(Tag)`
  ${space};
  ${({ variant }) => styles[variant]};
  ${({ isCondensed }) => isCondensed && `font-family: 'DINNextW01-Condensed', Arial, sans-serif;`};
  ${({ weight }) => weight && `font-weight: ${weightConfig[weight]}`};
  ${({ color }) => `color: ${colorsConfig[color]}`};
  ${({ transform }) => transform && `text-transform: ${transform};`};
  ${({ align }) => align && getAlign(align)};
  ${({ isResponsive, variant }) =>
    isResponsive &&
    `@media screen and (max-width: 992px) {
      ${mobileStyles[variant]};
    }`};
  ${({ withEllipsis }) =>
    withEllipsis &&
    `white-space: nowrap; overflow: hidden; text-overflow: ellipsis; min-width: 1px;`};
  ${({ withShadow }) => withShadow && `text-shadow: 0 0 4px ${colors.main.grey}`};
`;

StyledTypography.defaultProps = { theme };

export default StyledTypography;
