import { Action, StoreType } from 'reducers/types';
import {
  PROGRAMS_ACTION_TYPES,
  PROGRAM_SCHEDULE_ACTION_TYPES,
  SCHEDULE_ACTION_TYPES,
} from 'actions/programs';
import { ProgramsSource, ProgramsState, Program } from './types';

const initialState: ProgramsState = {
  total: 0,
  all: [],
  error: null,
  loading: false,
};

interface ProgramPayload extends ProgramsSource {
  error?: string;
}

const extractPrograms = (payload: ProgramPayload): Program[] => {
  const { userPrograms } = payload;

  return userPrograms.map(
    ({
      program,
      schedule,
      programTrack,
      completedSessionsCount,
      lastSession = null,
      nextSession = null,
    }): Program => ({
      ...program,
      id: program.id,
      schedule,
      programTrack,
      lastSession,
      nextSession,
      completedSessionsCount,
    }),
  );
};

const mergePrograms = (state: ProgramsState, payload: ProgramPayload) => {
  return state.all.map(item => {
    const updatedProgram = payload.userPrograms.find(i => i.program.id === item.id);
    const updatedProgramData = updatedProgram ? updatedProgram.program : null;
    const updatedSchedule = updatedProgram ? updatedProgram.schedule : null;
    return {
      ...item,
      ...updatedProgramData,
      schedule: updatedSchedule,
    };
  });
};

export default function Index(state = initialState, action: Action<ProgramPayload>) {
  switch (action.type) {
    case PROGRAMS_ACTION_TYPES.LOADING:
      if (state.loading || state.all.length > 0) {
        return state;
      }

      return {
        ...initialState,
        loading: true,
      };

    case PROGRAMS_ACTION_TYPES.LOADED:
      return {
        ...state,
        total: action.payload.total,
        all: extractPrograms(action.payload),
        loading: false,
        error: null,
      };

    case PROGRAMS_ACTION_TYPES.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case SCHEDULE_ACTION_TYPES.UPDATED:
      return {
        ...state,
        all: mergePrograms(state, action.payload),
      };

    case PROGRAM_SCHEDULE_ACTION_TYPES.UPDATED: {
      const extractedPrograms = extractPrograms(action.payload);
      const filteredPrograms = state.all.filter(program => program.id !== extractedPrograms[0].id);

      return {
        ...state,
        all: [...filteredPrograms, ...extractedPrograms],
      };
    }

    default:
      return {
        ...state,
      };
  }
}

export const getPrograms = (state: StoreType): ProgramsState => state.programs;

export const getProgramById = (state: StoreType, id: number): Program | undefined => {
  const { all: programs } = getPrograms(state);
  return programs.find(program => program.id === id);
};

export const getProgramTrack = (state: StoreType, programId: number) => {
  const program = getProgramById(state, programId);
  return program ? program.programTrack : null;
};
