import * as React from 'react';
import NProgress from 'nprogress';
import Router from 'next/router';

import colors from '../../constants/colors';

class NProgressClass extends React.PureComponent {
  componentDidMount() {
    const { options } = this.props;

    if (options) {
      NProgress.configure(options);
    }

    Router.events.on('routeChangeStart', this.routeChangeStart);
    Router.events.on('routeChangeComplete', this.routeChangeEnd);
    Router.events.on('routeChangeError', this.routeChangeEnd);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    Router.events.off('routeChangeStart', this.routeChangeStart);
    Router.events.off('routeChangeComplete', this.routeChangeEnd);
    Router.events.off('routeChangeError', this.routeChangeEnd);
  }

  routeChangeStart = () => {
    const { showAfterMs } = this.props;

    clearTimeout(this.timer);
    this.timer = setTimeout(NProgress.start, showAfterMs);
  };

  routeChangeEnd = () => {
    clearTimeout(this.timer);
    NProgress.done();
  };

  render() {
    const { color, spinner, isMobile } = this.props;

    return (
      <style jsx global>
        {`
          #nprogress {
            pointer-events: none;
          }
          #nprogress .bar {
            background: ${colors.main.lightBlue};
            position: fixed;
            z-index: 999999;
            top: 0;
            left: 0;
            width: 100%;
            height: 2px;
          }
          #nprogress .peg {
            display: block;
            position: absolute;
            right: 0px;
            width: 100px;
            height: 100%;
            opacity: 1;
            -webkit-transform: rotate(3deg) translate(0px, -4px);
            -ms-transform: rotate(3deg) translate(0px, -4px);
            transform: rotate(3deg) translate(0px, -4px);
          }
          #nprogress .spinner {
            display: ${spinner ? 'block' : 'none'};
            position: fixed;
            z-index: 1031;
            top: 15px;
            right: 15px;
          }
          #nprogress .spinner-icon {
            width: 18px;
            height: 18px;
            box-sizing: border-box;
            border: solid 2px transparent;
            border-top-color: ${color};
            border-left-color: ${color};
            border-radius: 50%;
            -webkit-animation: nprogresss-spinner 400ms linear infinite;
            animation: nprogress-spinner 400ms linear infinite;
          }
          .nprogress-custom-parent {
            overflow: hidden;
            position: relative;
          }
          .nprogress-custom-parent #nprogress .spinner,
          .nprogress-custom-parent #nprogress .bar {
            position: absolute;
          }
          @-webkit-keyframes nprogress-spinner {
            0% {
              -webkit-transform: rotate(0deg);
            }
            100% {
              -webkit-transform: rotate(360deg);
            }
          }
          @keyframes nprogress-spinner {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
          @media (max-width: 768px) {
            #nprogress,
            #nprogress .bar {
              top: ${isMobile ? 'initial' : '0px'};
              bottom: ${isMobile ? '60px' : 'initial'};
            }
          }
        `}
      </style>
    );
  }
}
NProgressClass.defaultProps = {
  color: '#2299DD',
  showAfterMs: 300,
  spinner: false,
};

export default NProgressClass;
