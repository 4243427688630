import { put, call, select } from 'redux-saga/effects';
import { findKey } from 'lodash';
import Router from 'next/router';
import * as Sentry from '@sentry/nextjs';
import { getUserCookies } from 'reducers/user';
import api from '../utils/api';
import { getCurrentCircuitRound, getCurrentSessionExercise } from '../reducers/exercises';
import { getWorkoutSessionId } from '../reducers/workout';
import {
  receiveFetchSessionExercisesList,
  failureFetchSessionExercisesList,
  receiveCompleteExercise,
  receiveFetchSignCrExerciseVideo,
  receiveFetchExerciseById,
  requestNextExercise,
} from '../actions/exercise';
import { recieveStartExercise } from '../actions/workout';
import { getProgramSession } from '../reducers/sessions';
import { fetchErrorHandler } from '../actions/fetchErrorHandler';

const getCircuitRound = state => {
  return getCurrentCircuitRound(state.exercises);
};

const getSessionExercise = state => {
  return getCurrentSessionExercise(state.exercises);
};

export const getWorkoutExerciseIdHelper = state => {
  const sessionExercise = getSessionExercise(state);
  const round = getCircuitRound(state);

  const { items } = state.workout.exercises || {};

  return findKey(items, w => {
    return w && w.sessionExerciseId === sessionExercise?.id && w.round === round;
  });
};

/**
 * Fetch session exercises list.
 */
export function* fetchSessionExercisesList(action) {
  try {
    const { sessionId, timeLength } = action.payload || {};
    const accessToken = yield select(getUserCookies);

    const { data } = yield call(
      api(accessToken).get,
      `/sessions/${sessionId}/session-exercises?timeOption=${timeLength}`,
    );

    yield put(receiveFetchSessionExercisesList({ timeLength, sessionExercises: data, sessionId }));
  } catch (error) {
    yield put(failureFetchSessionExercisesList('Something went wrong'));
    yield put(
      fetchErrorHandler(
        error.response,
        'Oops, we hit a snag fetching your exercises. Try refreshing the page',
      ),
    );
  }
}

// fetch signcr exercisevideo
export function* fetchSignCrExerciseVideo(action) {
  try {
    const { videoId } = action.payload || {};

    const accessToken = yield select(getUserCookies);

    const { data } = yield call(api(accessToken).get, `/file/video/${videoId}/sign_cf?format=hls`, {
      withCredentials: true,
    });

    yield put(receiveFetchSignCrExerciseVideo(data));
  } catch (error) {
    yield put(fetchErrorHandler(error.response, 'Something went wrong'));
  }
}

// complete exercise
export function* completeExercise() {
  try {
    const workoutExerciseId = yield select(getWorkoutExerciseIdHelper);

    if (!workoutExerciseId) {
      const { id, program } = yield select(getProgramSession);
      Router.push(`/programs/${program.id}/sessions/${id}`);
      throw Error('Exercise id is not defined');
    }

    const { data } = yield call(api().put, `/users/workout/exercise/${workoutExerciseId}`, {
      completed: true,
    });

    yield put(receiveCompleteExercise(data));
    yield put(requestNextExercise());
  } catch (error) {
    yield put(fetchErrorHandler(error.response, 'Something went wrong'));
  }
}

// start exercise
export function* startExercise(action) {
  const { sessionId } = action.payload;

  if (!sessionId) {
    throw Error('No session Id!');
  }

  const workoutExerciseId = yield select(getWorkoutExerciseIdHelper);

  if (!workoutExerciseId) {
    // If `workoutExerciseId` is not defined, it means that the
    // session exercise hasn't been started yet, and we start it here.
    try {
      const sessionExercise = yield select(getSessionExercise);
      const round = yield select(getCircuitRound);
      const workoutSessionId = yield select(getWorkoutSessionId);
      const body = {
        workoutSessionId,
        exerciseId: sessionExercise.exercise.id,
        sessionExerciseId: sessionExercise.id,
        phase: sessionExercise.phase,
        round,
      };

      Sentry.setContext('start workout exercise data', body);

      const { data } = yield call(api().post, `/users/workout/exercise`, body);
      yield put(recieveStartExercise({ ...data, sessionExercise: { id: sessionExercise.id } }));
    } catch (error) {
      Sentry.captureException('Unable to start user exercise', { extra: error });
      yield put(
        fetchErrorHandler(
          error.response,
          'Oops, we hit a snag starting your exercise. Try refreshing the page',
        ),
      );
    }
  }
}

// fetch exercise by id
export function* fetchExerciseById(action) {
  try {
    const { exerciseId } = action.payload || {};

    const accessToken = yield select(getUserCookies);

    const { data } = yield call(api(accessToken).get, `/exercise/${exerciseId}`);

    yield put(receiveFetchExerciseById(data));
  } catch (error) {
    yield put(
      fetchErrorHandler(
        error.response,
        'Oops, we hit a snag fetching your exercise. Try refreshing the page',
      ),
    );
  }
}
