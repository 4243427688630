import { createSelector } from 'reselect';
import moment from 'moment';

import { FETCH_ACHIEVEMENTS } from '../../actions/achievements';

const initialState = {
  all: null,
};

export default function Index(state = initialState, action) {
  switch (action.type) {
    case FETCH_ACHIEVEMENTS.success: {
      return {
        ...state,
        all: action.payload,
      };
    }

    default:
      return state;
  }
}

export const getAchievements = state => state.all;

// get only not viewed and earned achievements
export const getNotViewedAchievements = createSelector(getAchievements, achievements =>
  achievements
    ? achievements
        .filter(achievement => achievement.viewed === false && achievement.earnedAt)
        .sort((date1, date2) => {
          return (
            moment(date2.earnedAt).format('YYYYMMDD') - moment(date1.earnedAt).format('YYYYMMDD')
          );
        })
    : null,
);
