import crypto from 'crypto';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const hsSignature = email => {
  if (publicRuntimeConfig.beaconSecret) {
    return crypto
      .createHmac('sha256', publicRuntimeConfig.beaconSecret)
      .update(email)
      .digest('hex');
  }
  return undefined;
};

export const identify = user => {
  const { firstName, lastName, email } = user;
  const userObject = {
    email,
    signature: hsSignature(email),
  };
  const name = `${firstName} ${lastName}`.trim();
  if (name) {
    userObject.name = name;
  }
  window.Beacon('identify', userObject);
};

export const openBeacon = () => {
  window.Beacon('open');
};
