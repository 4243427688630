import { put } from 'redux-saga/effects';
import * as Sentry from '@sentry/nextjs';

import { showModal } from '../actions/modal';
import { OUTDATED } from '../constants';
import { showToastFetchError } from '../actions/toasts';
import packageInfo from '../../package.json';

export default function* fetchErrorHandler(action) {
  try {
    const { error, message } = action.payload;

    if (error?.status === 410 && error?.data?.message === OUTDATED && packageInfo) {
      yield put(
        showModal('SHOW_AVAILABLE_UPDATES', {
          data: JSON.parse(decodeURIComponent(error.data.data)),
          version: packageInfo.version,
        }),
      );
      return;
    }

    if (message) {
      yield put(showToastFetchError(message));
    } else if (error?.data?.message) {
      yield put(showToastFetchError(error?.data?.message));
    }
  } catch (err) {
    Sentry.captureException(err);
  }
}
