import { CLEAR_EXERCISES_STATE } from '../../actions/exercise';
import { SAVE_PONDER_BY_SESSION, CLEAR_PONDER_ALL } from '../../actions/ponder';

const initialState = {};

const Index = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_PONDER_BY_SESSION: {
      const { sessionId, ponder } = action.payload;
      return { ...state, [sessionId]: ponder };
    }

    case CLEAR_EXERCISES_STATE:
    case CLEAR_PONDER_ALL: {
      return initialState;
    }

    default:
      return state;
  }
};

export default Index;

export const getPonderInitialValues = (state, sessionId) => state.ponder[sessionId];
