import * as Sentry from '@sentry/nextjs';
import { isServer } from './getEnvironment';
import { SESSION_STATE_KEY } from '../constants';

export function saveToLocalStorage(state) {
  if (isServer()) {
    return;
  }

  try {
    const serialisedState = JSON.stringify({
      workout: {
        ...state.workout,
        fetched: false,
      },
      exercises: {
        ...state.exercises,
      },
      ponder: {
        ...state.ponder,
      },
      sessions: {
        sessionId: state.sessions.sessionId,
      },
    });
    localStorage.setItem(SESSION_STATE_KEY, serialisedState);
  } catch (e) {
    Sentry.captureException(e);
  }
}

export function loadFromLocalStorage() {
  if (isServer()) {
    return undefined;
  }

  const serialisedSessionState = localStorage?.getItem(SESSION_STATE_KEY);

  try {
    if (serialisedSessionState === null) {
      return undefined;
    }

    // don't apply session state if session resumes
    if (window.location.href.includes('resume')) {
      return undefined;
    }

    const state = JSON.parse(serialisedSessionState);

    if (window.location.href.includes('/ponder')) {
      return {
        ponder: state.ponder,
      };
    }

    if (window.location.href.includes('/start')) {
      const curentSessionId =
        Number(window.location.pathname.split('sessions/')[1]?.split('/')[0]) || 0;

      return state.exercises &&
        state.workout?.exercises?.items &&
        curentSessionId === state.sessions?.sessionId
        ? {
            workout: state.workout,
            exercises: state.exercises,
          }
        : undefined;
    }

    return undefined;
  } catch (e) {
    Sentry.setContext('JSON', serialisedSessionState);
    Sentry.captureException(e);
    return undefined;
  }
}
