import { Align } from './types';

const getAlign = (align: Align | [Align, Align]) => {
  if (typeof align === 'string') {
    return `text-align: ${align};`;
  }

  return `
    text-align: ${align[0]};

    @media screen and (max-width: 992px) {
      text-align: ${align[1]};
    }
  `;
};

export default getAlign;
