import styled, { css } from 'styled-components';
import { space } from 'styled-system';
import { rgba } from 'polished';
import colors from '../../constants/colors';

const margin = css`
  ${props => {
    if (props.margin) {
      return `
        margin-bottom: 15px;
      `;
    }
    return undefined;
  }}
`;

// rewrite with text component
const Wrapper = styled.button`
  ${space}
  ${margin}
  font-size: 16px !important;
  font-weight: 700 !important;
  background: linear-gradient(to right, ${colors.main.darkBlue}, ${colors.main.lightBlue});
  border-radius: 4px;
  border: none;
  box-shadow: 0 8px 36px ${rgba(colors.main.blue, 0.4)};
  transition: box-shadow 0.3s;
  cursor: pointer;
  color: ${colors.main.white};
  letter-spacing: 0.08em;
  text-transform: uppercase;
  height: 60px;
  width: 100%;

  ${props =>
    props.pressed &&
    css`
      background: #2f7aba !important;
      box-shadow: none !important;
    `}

  @media screen and (min-width: 766px) {
    &:hover {
      background: linear-gradient(to right, ${colors.main.darkBlue}, #19b1ec);
      box-shadow: 0 4px 16px ${rgba(colors.main.blue, 0.4)};
    }
  }

  &:disabled {
    background: ${colors.main.disabledBtns};
    box-shadow: none;
    cursor: default;
    color: ${colors.main.placeholdersAndDisabledBtnsText};
    pointer-events: none;
  }

  ${({ reduced }) =>
    reduced &&
    css`
      height: 35px;
      width: 180px;
    `}

  ${props =>
    props.secondary &&
    css`
      color: ${colors.main.black};
      background: ${colors.main.secondaryBtns};
      box-shadow: none;

      ${innerProps =>
        innerProps.pressed &&
        css`
          background: #bfc6ce !important;
        `}

      &:hover {
        background: #cbd1d8;
        box-shadow: none;
      }
    `}
`;

export default Wrapper;
