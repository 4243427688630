export default {
  main: {
    white: '#FFFFFF',
    black: '#21262D',
    blue: '#0EA0C3',
    brown: '#B79B67',
    lightBlue: '#16C0E8',
    darkBlue: '#2F7ABA',
    grey: '#6C7888',
    dividers: '#F0F2F5',
    disabledBtns: '#DADEE3',
    secondaryBtns: '#F8F9FA',
    placeholdersAndDisabledBtnsText: '#B6BEC9',
    royal: '#6F79AE',
    fandango: '#AC4887',
    ruby: '#d84181',
    darkRuby: '#AC4887',
    placeholdersForInput: '#DADEE3',
    textButtonHoverBackground: '#F8F9FA',
    green: '#78d080',
    blueGray: '#6c7888',
  },

  background: {
    maincolor: '#F0F2F5',
  },
};
