import { put, call, select } from 'redux-saga/effects';
import Router from 'next/router';
import _ from 'lodash';
import api from '../utils/api';
import { getQuestions, getUsersAnswers } from '../reducers/questionnaire';
import { getUser } from '../reducers/user';
import {
  receiveFetchQuestionnaire,
  failureFetchQuestionnaire,
  receiveSendQuestionnaireAnswers,
  failureSenedQuestionnaireAnswers,
  receiveFetchQuestionnaireAnswers,
  failureFetchQuestionnaireAnswers,
} from '../actions/questionnaire';
import { receiveFetchAchievements } from '../actions/achievements';
import { showModal } from '../actions/modal';
import { fetchErrorHandler } from '../actions/fetchErrorHandler';

// fetch questionnaire
export function* fetchQuestionnaire(action) {
  try {
    const { cookies } = yield select(getUser);
    const isProgramQuestionnaire = !!action.payload?.programId;
    const apiEndpoint = isProgramQuestionnaire
      ? `/questionnaire/programs/${action.payload.programId}`
      : `/questionnaire`;

    const { data } = yield call(api(cookies).get, apiEndpoint, action.payload);

    yield put(receiveFetchQuestionnaire(data));
  } catch (error) {
    yield put(fetchErrorHandler(error.response, 'Something went wrong'));
    yield put(failureFetchQuestionnaire('Something went wrong'));
  }
}

// send finished questionnaire by user
export function* sendQuestionnaireAnswers() {
  try {
    const { id, cookies } = yield select(getUser);
    const questions = yield select(getQuestions);
    const userAnswers = yield select(getUsersAnswers);

    const programQuestion = questions.find(q => q.programs?.length);
    const programId = programQuestion?.programs[0].id;

    // filter questions with answers
    const filteredQuestions = questions.map(question => {
      // find intersection between question answers and user answers
      const matchedAnswers = _.intersectionBy(question.answers, userAnswers[question.id], 'id');

      return { ...question, answers: matchedAnswers };
    });

    // filter questions with sub answers
    const filteredQuestionsSubAnswers = filteredQuestions.map(question => {
      // get user subanswers for current question
      const userSubAnswers = userAnswers[question.id]
        ? userAnswers[question.id].reduce((acc, userAnswer) => {
            return userAnswer.subAnswers ? acc.concat(userAnswer.subAnswers) : acc;
          }, [])
        : [];

      const answers = question.answers.map(answer => {
        // filter sub answers if its exists
        const filteredSubAnswers = answer.items
          ? answer.items.filter(item => !!~userSubAnswers.indexOf(item.id)) // eslint-disable-line no-bitwise
          : null;

        // assign new created filtered subanswers array to answer item
        return { ...answer, items: filteredSubAnswers };
      });

      return { ...question, answers };
    });

    let data;
    if (programId) {
      ({ data } = yield call(api(cookies).put, `/users/${id}/programs/${programId}/answers`, {
        questions: filteredQuestions,
      }));
    } else {
      ({ data } = yield call(api(cookies).post, '/questionnaire/user/answers', {
        questions: filteredQuestionsSubAnswers,
      }));
    }

    yield put(receiveSendQuestionnaireAnswers(data));

    if (!programId) {
      const { data: achievements } = yield call(api().get, `/users/achievements`);
      const notViewedAchievements = achievements.filter(
        achievement => achievement.viewed === false && achievement.earnedAt,
      );

      yield put(receiveFetchAchievements(achievements));

      Router.push('/').then(() => {
        // show achievements
        if (notViewedAchievements && notViewedAchievements.length) {
          put(showModal('ACHIEVEMENTS'));
        }
      });
    } else {
      const { data: userProgram = {} } = yield call(
        api(cookies).get,
        `/users/${id}/programs/${programId}`,
      );
      Router.push(`/programs/${programId}/sessions/${userProgram.nextSession.id}/start`);
    }
  } catch (error) {
    yield put(failureSenedQuestionnaireAnswers('Something went wrong'));
    yield put(fetchErrorHandler(error.response, 'Error while sending questionnaire answers'));
  }
}

// fetch user questionnaire answers
export function* fetchUserQuestionnaireAnswers() {
  try {
    const { cookies } = yield select(getUser);

    const { data } = yield call(api(cookies).get, '/questionnaire/user/answers');

    yield put(receiveFetchQuestionnaireAnswers(data));
  } catch (error) {
    yield put(failureFetchQuestionnaireAnswers('Something went wrong'));
    yield put(fetchErrorHandler(error.response, 'Error while fetching questionnaire answers'));
  }
}
