import { call, put } from 'redux-saga/effects';
import api from '../utils/api';
import { fetchErrorHandler } from '../actions/fetchErrorHandler';
import { receiveAdvertisementProduct, receiveAnnouncement } from '../actions/advertisement';

export default function* fetchAdvertisementProduct() {
  try {
    const { data } = yield call(api().get, `/advertisement/`);
    const { data: announcement } = yield call(api().get, `/admin/advertisement/current`);

    yield put(receiveAdvertisementProduct({ products: data || null }));
    yield put(receiveAnnouncement({ announcement: announcement || null }));
  } catch (error) {
    yield put(fetchErrorHandler(error.response, 'Something went wrong'));
  }
}
