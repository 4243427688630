// FETCH BLOG POSTS
export const FETCH_BLOG_POSTS = {
  request: 'FETCH_BLOG_POSTS_REQUEST',
  success: 'FETCH_BLOG_POSTS_SUCCESS',
  failure: 'FETCH_BLOG_POSTS_FAILURE',
};

export const requestFetchBlogPosts = payload => ({
  type: FETCH_BLOG_POSTS.request,
  payload,
});
export const receiveFetchBlogPosts = payload => ({
  type: FETCH_BLOG_POSTS.success,
  payload,
});
export const failureFetchBlogPosts = payload => ({
  type: FETCH_BLOG_POSTS.failure,
  payload,
});
