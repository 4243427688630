// REQUEST SESSION EXERCISES LIST
export const FETCH_SESSION_EXERCISES_LIST = {
  request: 'FETCH_SESSION_EXERCISES_LIST_REQUEST',
  success: 'FETCH_SESSION_EXERCISES_LIST_SUCCESS',
  failure: 'FETCH_SESSION_EXERCISES_LIST_FAILURE',
};

export const FETCH_BASIC_SESSION_EXERCISES_LIST = {
  success: 'FETCH_BASIC_SESSION_EXERCISES_LIST_SUCCESS',
};

export const requestFetchSessionExercisesList = payload => ({
  type: FETCH_SESSION_EXERCISES_LIST.request,
  payload,
});

export const receiveFetchSessionExercisesList = payload => ({
  type: FETCH_SESSION_EXERCISES_LIST.success,
  payload,
});

export const receiveFetchBasicSessionExercisesList = payload => ({
  type: FETCH_BASIC_SESSION_EXERCISES_LIST.success,
  payload,
});

export const failureFetchSessionExercisesList = payload => ({
  type: FETCH_SESSION_EXERCISES_LIST.failure,
  payload,
});

// GET NEXT EXERCISE
export const GET_NEXT_EXERCISE = 'GET_NEXT_EXERCISE';
export const requestNextExercise = () => ({ type: GET_NEXT_EXERCISE });

// GET PREV EXERCISE
export const GET_PREV_EXERCISE = 'GET_PREV_EXERCISE';
export const requestPrevExercise = () => ({ type: GET_PREV_EXERCISE });

// GET NEXT EXERCISE PHASE
export const GET_NEXT_EXERCISE_PHASE = 'GET_NEXT_EXERCISE_PHASE';
export const requestNextExercisePhase = () => ({
  type: GET_NEXT_EXERCISE_PHASE,
});

// GET PREV EXERCISE PHASE
export const GET_PREV_EXERCISE_PHASE = 'GET_PREV_EXERCISE_PHASE';
export const requestPrevExercisePhase = () => ({
  type: GET_PREV_EXERCISE_PHASE,
});

// COMPLETE EXERCISE
export const COMPLETE_EXERCISE = {
  request: 'COMPLETE_EXERCISE_REQUEST',
  success: 'COMPLETE_EXERCISE_SUCCESS',
};

export const requestCompleteExercise = () => ({
  type: COMPLETE_EXERCISE.request,
});

export const receiveCompleteExercise = payload => ({
  type: COMPLETE_EXERCISE.success,
  payload,
});

// GET SIGN_CR FOR EXERCISE VIDEO
export const FETCH_SIGN_EXERCISE_VIDEO = {
  request: 'FETCH_SIGN_EXERCISE_VIDEO_REQUEST',
  success: 'FETCH_SIGN_EXERCISE_VIDEO_SUCCESS',
};

export const requestFetchSignCrExerciseVideo = payload => ({
  type: FETCH_SIGN_EXERCISE_VIDEO.request,
  payload,
});

export const receiveFetchSignCrExerciseVideo = payload => ({
  type: FETCH_SIGN_EXERCISE_VIDEO.success,
  payload,
});

// GET EXERCISE BY ID
export const FETCH_EXERCISE_BY_ID = {
  request: 'FETCH_EXERCISE_BY_ID_REQUEST',
  success: 'FETCH_EXERCISE_BY_ID_SUCCESS',
};

export const requestFetchExerciseById = payload => ({
  type: FETCH_EXERCISE_BY_ID.request,
  payload,
});

export const receiveFetchExerciseById = payload => ({
  type: FETCH_EXERCISE_BY_ID.success,
  payload,
});

// CLEAR EXERCISES
export const CLEAR_EXERCISES_STATE = 'CLEAR_EXERCISES_STATE';

export const clearExercisesState = () => ({
  type: CLEAR_EXERCISES_STATE,
});

// ACTIONS FOR PLAYERS
export const SET_ASSESSMENT_SCREEN = 'SET_ASSESSMENT_SCREEN';
export const setAssessmentScreen = payload => ({
  type: SET_ASSESSMENT_SCREEN,
  payload,
});

export const SET_INTRO_SCREEN = 'SET_INTRO_SCREEN';
export const setIntroScreen = payload => ({
  type: SET_INTRO_SCREEN,
  payload,
});

// CLEAR_EXERCISE_DETAILS
export const CLEAR_EXERCISE_DETAILS = 'CLEAR_EXERCISE_DETAILS';
export const clearExerciseDetails = () => ({ type: CLEAR_EXERCISE_DETAILS });
