import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import {
  FETCH_WORKOUT_SESSIONS,
  FETCH_WORKOUT_SESSIONS_PROGRAM,
  FETCH_WORKOUT_SESSION_BY_ID,
  CREATE_WORKOUT,
  FETCH_WORKOUT_BY_ID,
  FETCH_WORKOUT_EXERCISES,
  FETCH_WORKOUT_SESSION,
  DONE_WORKOUT,
  SET_FILTER,
  START_EXERCISE,
  UPDATE_WORKOUT_SURVEY,
} from '../../actions/workout';

const initialState = {
  sessions: {
    isFetching: false,
    items: null,
    total: 0,
  },
  isWorkoutStarting: false,
  isWorkoutPondering: false,
  workoutSessionId: null,
  filter: { filters: null },
  exercises: {
    items: null,
  },
  completedExercises: [],
  fetched: false,
};

export default function Index(state = initialState, action) {
  switch (action.type) {
    case DONE_WORKOUT.idle: {
      return {
        ...state,
        isWorkoutPondering: false,
      };
    }
    case DONE_WORKOUT.request: {
      return {
        ...state,
        isWorkoutPondering: true,
      };
    }
    case DONE_WORKOUT.success: {
      return {
        ...state,
      };
    }
    case DONE_WORKOUT.failure: {
      return {
        ...state,
        isWorkoutPondering: false,
      };
    }
    case CREATE_WORKOUT.idle: {
      return {
        ...state,
        isWorkoutStarting: false,
      };
    }
    case CREATE_WORKOUT.request: {
      return {
        ...state,
        isWorkoutStarting: true,
      };
    }
    case CREATE_WORKOUT.failure: {
      return {
        ...state,
        isWorkoutStarting: false,
      };
    }
    case CREATE_WORKOUT.success: {
      // fetch user workout by id, and if user has already workouts then merge it
      const { items: totalSessions = null } = state.sessions;

      return {
        ...state,
        sessions: {
          ...state.sessions,
          items: _.unionBy(totalSessions, [action.payload], 'id'),
        },
        workoutSessionId: action.payload.id,
      };
    }

    case FETCH_WORKOUT_SESSION.request:
    case FETCH_WORKOUT_BY_ID.request: {
      return {
        ...state,
        fetched: false,
      };
    }

    case FETCH_WORKOUT_SESSION.success:
    case FETCH_WORKOUT_BY_ID.success: {
      return {
        ...state,
        workoutSessionId: action.payload.id,
        preparationPhaseAnswer: action.payload.preparationPhaseAnswer,

        // list of completed exercises for ponder survey
        completedExercises: _.uniqBy(
          action.payload.workoutExercises || [],
          e => `${e.exercise.id} - ${e.phase}`,
        ),

        // datetime data of session for completed session modal stat
        sessionDateTimeData: {
          startedAt: action.payload.startedAt,
          completedAt: action.payload.completedAt,
          timeLength: action.payload.timeLength,
        },
        fetched: true,
      };
    }

    case FETCH_WORKOUT_SESSION.failure:
    case FETCH_WORKOUT_BY_ID.failure: {
      return {
        ...state,
        fetched: true,
      };
    }

    case FETCH_WORKOUT_SESSIONS.request: {
      return {
        ...state,
        sessions: {
          ...state.sessions,
          isFetching: true,
        },
      };
    }

    case FETCH_WORKOUT_SESSIONS.success: {
      // fetch user workouts, and if user has already workouts then merge
      const prevItems = state.sessions.items ?? [];
      return {
        ...state,
        // Temporary solution for GF-430. Should be refactored
        workoutSessionId: action.payload.sessions[0]?.id || state.workoutSessionId,
        sessions: {
          isFetching: false,
          total: action.payload.total,
          items: _.unionBy([...prevItems, ...action.payload.sessions], 'id'),
        },
      };
    }

    case FETCH_WORKOUT_SESSIONS_PROGRAM.success: {
      // fetch user workouts, and if user has already workouts then merge
      const prevItems = state.sessions.items || [];

      return {
        ...state,
        // Temporary solution for GF-430. Should be refactored
        sessions: {
          isFetching: false,
          total: action.payload.total,
          items: _.unionBy([...action.payload.sessions, ...prevItems], 'id'),
        },
      };
    }

    case FETCH_WORKOUT_SESSION_BY_ID.success: {
      // fetch user workout by id, and if user has already workouts then merge
      const { items: totalSessions = null } = state.sessions;
      const { sessions = null, total = 0 } = action.payload;

      if (!sessions) return { ...state };
      if (!totalSessions)
        return {
          sessions: {
            ...state.sessions,
            items: [...sessions],
            total,
          },
        };

      return {
        ...state,
        sessions: {
          ...state.sessions,
          items: _.unionBy(totalSessions, sessions, 'id'),
          total,
        },
      };
    }

    case SET_FILTER.success: {
      return {
        ...state,
        filter: action.payload,
      };
    }

    case FETCH_WORKOUT_EXERCISES.success: {
      const workoutExercises = action.payload;
      return {
        ...state,
        exercises: {
          ...state.exercises,
          items: workoutExercises.reduce((acc, workoutExercise) => {
            if (!workoutExercise.sessionExercise) {
              return acc;
            }

            acc[workoutExercise.id] = {
              sessionExerciseId: workoutExercise.sessionExercise.id,
              round: workoutExercise.round,
            };
            return acc;
          }, {}),
        },
      };
    }

    case START_EXERCISE.success: {
      const { payload } = action;
      const workoutExerciseId = payload.id;
      const workout = {
        sessionExerciseId: payload.sessionExercise.id,
        round: payload.round,
      };
      return {
        ...state,
        exercises: {
          ...state.exercises,
          items: {
            ...state.exercises?.items,
            [workoutExerciseId]: {
              ...workout,
            },
          },
        },
      };
    }

    case UPDATE_WORKOUT_SURVEY.request: {
      const { id } = action.payload;
      return {
        ...state,
        sessions: {
          ...state.sessions,
          items: state.sessions.items.map(item => {
            if (item.id === id) {
              return {
                ...item,
                isUpdating: true,
              };
            }
            return item;
          }),
        },
      };
    }

    case UPDATE_WORKOUT_SURVEY.success: {
      const { id, workoutSurvey } = action.payload;

      return {
        ...state,
        sessions: {
          ...state.sessions,
          items: state.sessions.items.map(item => {
            if (item.id === id) {
              return {
                ...item,
                workoutSurvey,
                isUpdating: false,
              };
            }
            return item;
          }),
        },
      };
    }

    case UPDATE_WORKOUT_SURVEY.failure: {
      const { id } = action.payload;

      return {
        ...state,
        sessions: {
          ...state.sessions,
          items: state.sessions.items.map(item => {
            if (item.id === id) {
              return {
                ...item,
                isUpdating: false,
              };
            }
            return item;
          }),
        },
      };
    }

    default:
      return state;
  }
}

// get user sessions (activities)
export const getUserWorkoutSessions = createSelector(
  state => state.sessions,
  ({ items }) => {
    const limit = 0;
    if (items && items.length) {
      const filteredWorkouts = items.filter(item => item.workoutSurvey);
      const workouts = limit
        ? items.filter(item => item.workoutSurvey).slice(0, limit)
        : filteredWorkouts;

      if (workouts && workouts.length) {
        const sessionsTotal = _.uniqBy(
          workouts.map(workout => workout.session),
          'id',
        ).length;
        const programsTotal = _.uniqBy(
          workouts.map(workout => workout.session.program),
          'id',
        ).length;

        return {
          programsTotal,
          total: sessionsTotal,
          items: workouts,
        };
      }
      return null;
    }
    return null;
  },
);

// get filtered
export const getFilteredUserWorkoutSessions = createSelector(
  state => state.sessions,
  state => state.filter,
  ({ items }, filter) => {
    if (items && items.length && filter.filters) {
      const workouts = items.filter(item => item.workoutSurvey);
      const filteredWorkouts = workouts.filter(
        v => filter.filters.indexOf(v.session.program.name) !== -1,
      );

      if (filteredWorkouts && filteredWorkouts.length) {
        const sessionsTotal = _.uniqBy(
          filteredWorkouts.map(workout => workout.session),
          'id',
        ).length;
        const programsTotal = _.uniqBy(
          filteredWorkouts.map(workout => workout.session.program),
          'id',
        ).length;

        return {
          programsTotal,
          total: sessionsTotal,
          items: filteredWorkouts,
        };
      }
      return {
        programsTotal: 0,
        total: 0,
      };
    }
    return null;
  },
);

// get user workout sessions by program id
export const getUserWorkoutSessionsProgram = props =>
  createSelector(
    state => getUserWorkoutSessions(state.workout),
    workoutSessions => {
      const programId = Number(props.programId);
      const { items: allWorkouts = null } = workoutSessions || {};
      if (allWorkouts) {
        // retrieve workouts for current program, cause endpoint API doesn't work
        const programWorkouts = allWorkouts.filter(
          workout => workout.session.program.id === programId,
        );

        return programWorkouts;
      }
      return null;
    },
  );

// get last user sessions (activities)
export const getLastUserWorkoutSessions = createSelector(
  state => state.sessions,
  sessions => {
    const { items = null } = sessions || {};

    // get programs ids
    const filteredItems = items ? items.filter(item => item.workoutSurvey) : [];
    const slicedItems =
      filteredItems && filteredItems.length > 4 ? filteredItems.slice(0, 4) : filteredItems;

    // get unique array of sessions
    const totalSessions = _.uniqBy(
      slicedItems.map(item => item.session),
      'id',
    );
    // get unique array of programs
    const totalPrograms = _.uniqBy(
      slicedItems.map(item => item.session.program),
      'id',
    );

    return {
      totalPrograms: totalPrograms.length,
      totalSessions: totalSessions.length,
      items: slicedItems,
    };
  },
);

// get user workout session by session id
export const getUserProgramWorkoutsBySessionId = createSelector(
  state => state.sessions.sessionId,
  state => state.workout.sessions,
  (sessionId, workoutSessions) => {
    const workoutSessionsItems = workoutSessions ? workoutSessions.items : null;

    return sessionId && workoutSessionsItems
      ? workoutSessionsItems.filter(workout => workout.session && workout.session.id === sessionId)
      : null;
  },
);

// get last user workout session activity
export const getLastUserWorkoutSessionDate = createSelector(
  state => state.sessions,
  sessions => {
    const { items = null } = sessions || {};

    if (!items) return null;

    return items && items.length ? moment(items[0].startedAt).format('dddd, MMM DD') : null;
  },
);

// get user workout session id
export const getWorkoutSessionId = state => state.workout.workoutSessionId || null;

// get completed exercises during the workout session id
export const getCompletedExercises = state => {
  const exercisesToBeSorted = state.workout.completedExercises
    ? state.workout.completedExercises
    : state.exercises.sessionExercises;

  const exercises = exercisesToBeSorted.sort((a, b) => {
    return a.phase - b.phase || a.order - b.order;
  });

  return exercises.reduce((prev, cur) => {
    if (cur.sessionExercises) {
      // eslint-disable-next-line no-param-reassign
      prev = prev.concat(cur.sessionExercises);
    } else {
      prev.push(cur);
    }

    return prev;
  }, []);
};

// get datetime data of session for completed session modal stat
export const getSessionDateTimeData = state => state.workout.sessionDateTimeData || {};

// get user workout session id
export const getWorkoutById = createSelector(
  state => getWorkoutSessionId(state),
  state => state.workout.sessions,
  (currentWorkoutId, workoutSessions) => {
    const { items } = workoutSessions || {};

    if (items && items.length) {
      return items.reduce((acc, item) => (item.id === currentWorkoutId ? item : acc), null);
    }
    return null;
  },
);

// get is workout starting
export const getIsWorkoutStarting = state => state.workout.isWorkoutStarting || null;
// get is workout pondering
export const getIsWorkoutPondering = state => state.workout.isWorkoutPondering || null;
// get is workout starting
export const getIsWorkoutAPPosting = state => state.workout.isAPPosting || null;

export const getWorkoutFetched = state => state.workout.fetched;
