import { ActionPayload } from 'reducers/activities/types';

export enum FETCH_ACTIVITIES {
  request = 'FETCH_ACTIVITIES_REQUEST',
  success = 'FETCH_ACTIVITIES_SUCCESS',
  failure = 'FETCH_ACTIVITIES_FAILURE',
}

export const requestFetchActivities = (payload: ActionPayload) => ({
  type: FETCH_ACTIVITIES.request,
  payload,
});

export const receiveFetchActivities = (payload: ActionPayload) => ({
  type: FETCH_ACTIVITIES.success,
  payload,
});

export const failureFetchActivities = () => ({
  type: FETCH_ACTIVITIES.failure,
});
