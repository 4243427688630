/* eslint-disable consistent-return */
import getConfig from 'next/config';
import axios from 'axios';
import * as Sentry from '@sentry/nextjs';
import packageInfo from '../../package.json';
import { isServer } from './getEnvironment';

const { publicRuntimeConfig } = getConfig();
const API_BASEURL = publicRuntimeConfig.apiUrl;

class RestAPIError extends Error {
  constructor({ message, requestUrl = '' }) {
    super(message);

    let targetController;
    const [, mainPart, , subPart] = requestUrl.split('api')?.[1]?.split('/');
    if (mainPart === 'users' && subPart) {
      targetController = subPart;
    } else {
      targetController = mainPart;
    }

    this.name = `RestAPIError: ${targetController}`;
  }
}

// Pass cookies as optional parameter to use it in SSR
export default function Api(cookies) {
  const instanceAxios = axios.create({
    baseURL: API_BASEURL,
    timeout: Number(publicRuntimeConfig.axiosTimeout),
  });

  if (cookies && cookies.sAccessToken && isServer()) {
    instanceAxios.defaults.headers.common.Cookie = `sAccessToken=${cookies.sAccessToken};`;
  }

  instanceAxios.interceptors.request.use(
    req => {
      if (req.url.startsWith(API_BASEURL)) {
        req.headers['App-Version'] = packageInfo.version;
      }
      return req;
    },
    error => {
      return Promise.reject(error);
    },
  );

  instanceAxios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      const { response } = error;
      const status = response ? response.status : null;

      if (status === 401) {
        if (!isServer()) {
          const returnTo = window.location.href.replace(publicRuntimeConfig.baseUrl, '');
          window.location.replace(`${publicRuntimeConfig.baseUrl}/auth?returnTo=${returnTo}`);
        }
        return;
      }

      if (status && status !== 404 && status !== 401 && status !== 400 && navigator.onLine) {
        Sentry.setContext('response', {
          url: response?.request?.responseURL,
          sendingData: JSON.stringify(response?.config?.data),
          response: JSON.stringify(response?.request?.response),
        });
        Sentry.captureException(
          response?.data
            ? new RestAPIError({
                message: response?.data?.message,
                requestUrl: response?.request?.responseURL,
              })
            : error,
        );
      }

      return Promise.reject(response?.data || error);
    },
  );

  return instanceAxios;
}
